<template>
  <v-bottom-navigation bg-color="primary-lighten-2">
    <!-- Hidden item to fake the home button with -->
    <v-btn :to="{ name: 'home' }" value="1">
      {{ $t("common.domain.home") }}
      <v-icon icon="mdi-home" />
    </v-btn>

    <v-menu location="top center">
      <template #activator="{ props }">
        <v-btn id="insightMenu" value="2" v-bind="props">
          {{ $t("common.domain.insight") }}
          <v-icon icon="mdi-text-box-search" />
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-list-item
          v-for="({ to, name }, i) in getInsightItems"
          :key="i"
          class="py-5"
          :to="{ path: to }"
          :title="name"
          active-class="bg-accent"
          :active="$route.path.startsWith(to)"
        />
      </v-list>
    </v-menu>

    <tools-menu icon-activator menu-up />
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "MobileNavigationB",
  computed: {
    getInsightItems() {
      // Pluralise the words based on display size, so when we show the word "Insight" we don't pluralise.
      const isShrunk = this.$vuetify.display.mdAndDown ? 1 : 2;
      const sectorName = this.$t("common.domain.sector", isShrunk);
      const clientName = this.$t("common.domain.client", isShrunk);
      const matterName = this.$t("common.domain.matter", isShrunk);
      const peopleName = this.$t("common.domain.person", 2); // We never use "Person" here

      return [
        { to: "/sectors", name: sectorName, icon: "mdi-factory" },
        { to: "/clients", name: clientName, icon: "mdi-account-group" },
        { to: "/matters", name: matterName, icon: "mdi-briefcase" },
        { to: "/people", name: peopleName, icon: "mdi-book-account" },
      ];
    },
  },
};
</script>
