<template>
  <div>
    {{ getAppName }}
  </div>
</template>

<script>
export default {
  name: "AppName",
  computed: {
    getAppName() {
      return import.meta.env.VITE_NAME || "HSF Insight";
    },
  },
};
</script>
