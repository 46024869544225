const getters = {
  isSecureMatter(state) {
    return state.matter && state.matter.secure === true;
  },
  getPeople(state) {
    if (!state.matter?.staff) {
      return [];
    }

    return state.matter.staff;
  },
  getTopTimekeepers(state) {
    const entries = {};
    state.time.forEach((entry) => {
      const itemName = entry["timekeeper"];
      if (!entries[itemName]) {
        entries[itemName] = 0;
      }

      entries[itemName] =
        parseFloat(entries[itemName]) + parseFloat(entry["totalHours"]);
    });

    const tks = Object.entries(entries);

    return tks.sort((a, b) => b[1] - a[1]);
  },
  isAustralianMatter(state) {
    return state.matter.meta?.sourceDatabase === "AUS";
  },

  /**
   * Change to `mapState` once migrated to v2 of APIs
   */
  hasMatterPlan(state) {
    // Force the conversion of the string to a boolean with !!
    return state.matterPlan && !!state.matterPlan?.name?.trim();
  },
  /**
   * Change to `mapState` once migrated to v2 of APIs
   */
  getMatterPlan(state) {
    return {
      name: state.matterPlan.name,
      code: state.matterPlan.code,
      currency: state.matter.currencyCode,
    };
  },
  getMatterCurrency(state) {
    return state.matter?.currency;
  },
};

const actions = {
  /**
   * Reset a matter back to it's original state
   */
  resetMatter({ commit }) {
    ["errorMsg", "errorMsgClient", "errorMsgTime", "errorMsgPlan"].forEach(
      (value) => {
        commit("setError", { type: value, msg: null });
      }
    );

    commit("setTime", []);
    commit("setMatter", {});
    commit("setMatterClient", {});
    commit("setMatterCode", null);
    commit("setMatterPlan", {});
    commit("setHurricaneEstimate", {});
    commit("setExpertEstimate", {});
    commit("setIsChildMatter", false);

    ["isLoading", "isTimeLoading", "isClientLoading", "isPlanLoading"].forEach(
      (value) => {
        commit("setLoading", { type: value, state: false });
      }
    );
  },
  /**
   * Set the matter
   */
  setMatter({ commit }, matter) {
    commit("setMatter", matter);
  },
  /**
   * Set the group mambers
   */
  setGroupMembers({ commit }, groupMembers) {
    commit("setGroupMembers", groupMembers);
  },
  /**
   * Set the group details
   */
  setGroupDetails({ commit }, groupMembers) {
    commit("setGroupDetails", groupMembers);
  },

  /**
   * Set if a Matter is a child matter in the family
   */
  setIsChildMatter({ commit }, isChild) {
    commit("setChildMatter", isChild);
  },
};

const mutations = {
  /**
   * Set the error state for multiple values from a single mutator
   * @param {*} state Vuex state
   * @param {Object} error
   * @param {String} error.type
   * @param {Boolean} error.msg
   */
  setError(state, error) {
    if (!error.type) {
      throw new Error("Missing `type` from setError mutation.");
    }

    state[error.type] = error.msg;
  },

  /**
   * Set the loading state for multiple values from a single mutator
   * @param {*} state Vuex state
   * @param {Object} isLoading
   * @param {String} isLoading.type
   * @param {Boolean} isLoading.state
   */
  setLoading(state, isLoading) {
    if (!isLoading.type && !isLoading.state) {
      throw new Error("Missing `state` or `type` from isLoading mutation.");
    }

    state[isLoading.type] = isLoading.state;
  },

  /**
   * ****** MATTER MUTATIONS
   */

  /**
   * Set the current matter
   * @param {*} state The state
   * @param {*} matter Object representing the matter
   */
  setMatter(state, matter) {
    state.matter = matter;
  },
  setMatterCode(state, matterCode) {
    state.matterCode = matterCode;
  },
  setTime(state, timeData) {
    if (!timeData) {
      state.time = [];
      return;
    }

    state.time = timeData;
  },
  setMatterClient(state, clientData) {
    state.matterClient = clientData;
  },

  /**
   * Matter Estimation
   * @param {*} state
   * @param {*} plan
   */
  setMatterPlan(state, plan) {
    state.matterPlan = plan;
  },
  setHurricaneEstimate(state, estimate) {
    state.estimateHurricane = estimate;
  },
  setExpertEstimate(state, estimate) {
    state.estimateExpert = estimate;
  },

  /**
   * ****** MATTER GROUP MUTATIONS
   */
  setGroupMembers(state, groupMembers) {
    state.groupMembers = groupMembers;
  },
  setGroupDetails(state, groupDetails) {
    state.groupDetails = groupDetails;
  },
  setMatterGroupLoading(state, matterGroupLoading) {
    state.matterGroupLoading = matterGroupLoading;
  },
  setMatterGroupLoadError(state, matterGroupLoadError) {
    state.matterGroupLoadError = matterGroupLoadError;
  },

  /**
   * Matter Services Mutations
   * @param {*} state
   * @param {*} payload
   */
  setMatterServiceData(state, payload) {
    state.matterServiceData = payload;
  },

  /**
   * Set whether a matter is a child matter from the family
   */
  setChildMatter(state, isChildMatter) {
    state.isChildMatter = isChildMatter;
  },

  /**
   * Set genesis type
   * @param {*} state
   * @param {*} payload
   */
  setGenesisType(state, payload) {
    state.genesisType = payload;
  },
};

const state = {
  // Matter Core
  matter: {},
  matterCode: null,
  isLoading: false,
  errorMsg: "",

  // Matter Time
  time: [],
  isTimeLoading: false,
  errorMsgTime: null,

  // Matter Clients
  matterClient: {},
  isClientLoading: false,
  errorMsgClient: null,

  // Matter Plan & Estimates
  matterPlan: {},
  estimateExpert: {},
  estimateHurricane: {},
  isPlanLoading: false,
  errorMsgPlan: null,

  // Matter Groups
  groupMembers: [],
  groupDetails: [],
  matterServiceData: [],

  // Matter Family
  isChildMatter: false,

  // Genesis
  genesisType: null,
  matterGroupLoading: false,
  matterGroupLoadError: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
