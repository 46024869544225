import { createStore } from "vuex";
import CoreStore from "@/store/core.js";
import MattersStore from "@/modules/matters/_store/mattersStore.js";
import MatterStore from "@/modules/matters/_store/matterStore.js";
import PersonStore from "@/modules/people/_store/personStore.js";
import SearchStore from "@/modules/search/_store/searchStore.js";
import ClientStore from "@/modules/clients/_store/clientStore.js";
import GenesisStore from "@/modules/genesis/_store/genesisStore.js";
import SectorsStore from "@/modules/sectors/_store/sectorsStore.js";
import MagnoliaStore from "@/modules/sectors/_store/magnoliaStore.js";

const debug = import.meta.env.DEV;

export default (app) =>
  createStore({
    modules: {
      // General cross-app functions
      core: CoreStore(app),
      // State for matter selection/controls
      matters: MattersStore,
      // State for individual matter view
      matter: MatterStore,
      // State for viewing a person record
      person: PersonStore(app),
      // Store for handling search/find results
      search: SearchStore(app),
      // State for individual client view
      client: ClientStore(app),
      // State for maintaining overview of sectors (Sector Hub)
      sectors: SectorsStore(app),
      // State for maintaining overview of Magnolia Content - Tours (Sector Hub)
      tours: MagnoliaStore(app),
      // State for individual genesis view
      genesis: GenesisStore(app),
    },
    strict: debug,
  });
