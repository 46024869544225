const viewClientName = "view-client";
const baseTitle = "Client Insight // {id}";
const backToClient = {
  name: "Back to Client",
  to: viewClientName,
};

export default [
  {
    name: viewClientName,
    path: "overview",
    component: () => import("@/modules/clients/_views/ClientView.vue"),
    meta: {
      title: baseTitle,
      hasSidebar: true,
      hasFinancialYearSelector: true,
    },
  },
  {
    name: "client-people",
    path: "people",
    component: () => import("@/modules/clients/_views/ClientPeople.vue"),
    props: true,
    meta: {
      title: `${baseTitle} // People`,
      hasSidebar: true,
      backButton: backToClient,
    },
  },
  {
    name: "client-matters",
    path: "matters",
    component: () => import("@/modules/clients/_views/ClientMatters.vue"),
    props: true,
    meta: {
      title: `${baseTitle} // Matters`,
      hasSidebar: true,
      hasFinancialYearSelector: true,
      backButton: backToClient,
    },
  },
  {
    name: "client-billing-entities",
    path: "entities/:entityId",
    component: () => import("@/modules/clients/_views/ClientEntities.vue"),
    props: true,
    meta: {
      title: `${baseTitle} // Entities`,
      hasSidebar: true,
      backButton: {
        name: "Back to Client",
        to: viewClientName,
        query: { open: "billingEntities" },
      },
    },
  },
  {
    path: "credentials",
    name: "client-credentials",
    component: () => import("@/modules/clients/_views/ClientCredentials.vue"),
    props: true,
    meta: {
      title: `${baseTitle} // Credentials`,
      hasSidebar: true,
      hasFinancialYearSelector: true,
      backButton: backToClient,
    },
  },
];
