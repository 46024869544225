<template>
  <v-app-bar
    location="top"
    :scroll-behaviour="!isSearchOpen ? 'hide' : undefined"
    flat
    :color="getAppBarColor"
    density="comfortable"
    class="pa-1"
  >
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          id="homeButton"
          variant="flat"
          icon
          color="transparent"
          class="mr-2"
          :to="{ name: 'home' }"
          v-bind="props"
        >
          <app-logo :inverted="true" :height="42" />
        </v-btn>
      </template>
      <app-name class="d-inline-block" /> {{ $t("common.domain.home") }}
    </v-tooltip>

    <div v-if="!collapseInsightMenu" class="menu-buttons">
      <v-btn
        v-for="{ to, name } in getMainLinks"
        :key="to"
        :variant="$route.path.startsWith(to) ? 'text' : 'flat'"
        :color="$route.path.startsWith(to) ? 'accent' : getAppBarColor"
        class="mr-1"
        :to="{ path: to }"
      >
        {{ name }}
      </v-btn>
    </div>

    <div v-else class="menu-buttons">
      <v-menu location="bottom">
        <template #activator="{ props }">
          <v-btn variant="flat" :color="getAppBarColor" v-bind="props">
            {{ $t("common.domain.insight") }}
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item
            v-for="{ to, name, icon } in getMainLinks"
            :key="to"
            class="py-4"
            active-class="bg-accent"
            :active="$route.path.startsWith(to)"
            :title="name"
            :to="{ path: to }"
            :append-icon="icon"
          />
        </v-list>
      </v-menu>
    </div>

    <tools-menu text-activator :color="getAppBarColor" />

    <v-spacer />

    <search-box id="searchBox" @search-visible="onSearching" />

    <notifications-icon v-if="$featureFlag.isVisible('enableNotifications')" />

    <user-settings :avatar-size="42" />

    <reload-site
      min-width="450"
      max-width="550"
      right
      bottom
      nudge-left="500"
    />
  </v-app-bar>
</template>

<script>
import { USER_ROLE } from "@/config/constants.js";

export default {
  name: "DesktopNavigation",
  data: () => ({
    offsetTop: 0,
    isSearchOpen: false,
  }),
  computed: {
    collapseInsightMenu() {
      return this.$vuetify.display.mdAndDown;
    },
    getAppBarColor() {
      return this.$vuetify.theme.current.dark ? "primary" : "primary-lighten-2";
    },
    getMainLinks() {
      // Pluralise the words based on display size, so when we show the word "Insight" we don't pluralise.
      const isShrunk = this.$vuetify.display.mdAndDown ? 1 : 2;
      const sectorName = this.$t("common.domain.sector", isShrunk);
      const clientName = this.$t("common.domain.client", isShrunk);
      const matterName = this.$t("common.domain.matter", isShrunk);
      const peopleName = this.$t("common.domain.person", 2); // We never use "Person" here

      if (this.$auth.hasRole(USER_ROLE["sectorHubAccess"])) {
        return [
          { to: "/sectors", name: sectorName, icon: "mdi-factory" },
          { to: "/clients", name: clientName, icon: "mdi-account-group" },
          { to: "/matters", name: matterName, icon: "mdi-briefcase" },
          { to: "/people", name: peopleName, icon: "mdi-book-account" },
        ];
      }

      return [
        { to: "/clients", name: clientName, icon: "mdi-account-group" },
        { to: "/matters", name: matterName, icon: "mdi-briefcase" },
        { to: "/people", name: peopleName, icon: "mdi-book-account" },
      ];
    },
  },
  methods: {
    onSearching(isVisible) {
      this.isSearchOpen = isVisible;
    },
  },
};
</script>
