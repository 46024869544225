export default class LitigationAPI {
  #baseURL = `${import.meta.env.VITE_HSF_API_BASE_URL}/genesis/litigation`;
  #ENTITY = "Litigation";

  constructor(http) {
    this.http = http;
  }

  /**
   * Add a single arbitration to matter groups.
   * @param {Object} data {
            matterGroup: matter group id,
            arbitrationName: arbitration name by user,
        }
   */
  async addLitigation(data) {
    if (!data.matterGroup && !data.genesisName) {
      throw new Exception("Data is invalid");
    }
    const sendData = {
      matterGroup: data.matterGroup,
      litigationName: data.genesisName,
    };

    return this.http.post(`${this.#baseURL}/litigation`, sendData);
  }

  /**
   * Get the arbitrations lists
   * @param {String} id The Matter group id.
   */
  async getGenesisData(id) {
    return this.http.get(`${this.#baseURL}/matters/groups/${id}/litigations`);
  }

  /**
   * Get the component List
   * @param {String} litigationId The arbitration id.
   */
  async getGenesisComponents(litigationId) {
    return this.http.get(
      `${this.#baseURL}/litigation/${litigationId}/components`
    );
  }

  /**
   * Get the arbitration fields
   * @param {String} arbitrationId The arbitration id.
   */
  async getFields(arbitrationId) {
    return this.http.get(
      `${this.#baseURL}/litigation/${arbitrationId}/fields`,
      {
        params: {
          CoreFieldType: this.#ENTITY,
        },
      }
    );
  }

  /**
   * Get the components fields
   * @param {String} litigationId The id of the arbitration
   * @param {String} entityId The id of that entity
   * @param {String} componentName The name of the component
   * @returns {Promise}
   */
  async getEntityFields(litigationId, entityId, componentName) {
    if (!litigationId) {
      throw new Exception("Litigation id is required");
    }
    componentName = this.removeExtraChar(componentName);
    const url = `${this.#baseURL}/litigation/${litigationId}/fields`;
    return this.http.get(url, {
      params: {
        CoreFieldType: componentName,
        EntityId: entityId,
      },
    });
  }

  removeExtraChar(name) {
    return name.replace(/-|\s/g, "");
  }

  /**
   * Delete the entity from component
   * @param {String} litigationId The id of the litigation
   * @param {String} entityId The id of that entity
   *  @param {String} componentName The name of that component
   * @returns {Promise}
   */
  async deleteComponent(litigationId, entityId, componentName) {
    if (!litigationId || !entityId || !componentName) {
      throw new Exception("Data is invalid");
    }
    componentName = this.removeExtraChar(componentName);
    return this.http.delete(
      `${this.#baseURL}/litigation/${litigationId}/${componentName}/${entityId}`
    );
  }

  /**
   * Add a single component to litigation.
   * @param {String} litigationId The litigation id.
   * @param {Object} data {
            componentId: component id,
        }
   */
  async addComponentToGenesis(data, litigationId) {
    if (!data.componentId && !litigationId) {
      throw new Exception("Data is invalid");
    }
    return this.http.post(
      `${this.#baseURL}/litigation/${litigationId}/components`,
      data
    );
  }

  /**
   * POST a new single select choice
   * @param {Number} LookupId to set
   * @param {String} LookupValue An new choice to set
   * @returns Promise
   */
  async addNewSelectChoice(LookupId, LookupValue) {
    if (!LookupId) {
      throw new Error("Lookup Id is missing from request.");
    }
    const body = {
      LookupValue: LookupValue,
    };
    return this.http.post(`${this.#baseURL}/lookup/${LookupId}`, body);
  }

  /**
   * POST a new user to sharepoint site record
   * @param {String} role An user role to set
   * @param {String} firstName An first name to set
   * @param {String} lastName An last name to set
   * @returns Promise
   */
  async addNewSharePointUser(firstName, lastName, role) {
    if (!role) {
      throw new Error("User role is missing from request.");
    }
    const body = {
      firstName: firstName,
      lastName: lastName,
      role: role,
    };
    return this.http.post(`${this.#baseURL}/litigation/sp/role`, body);
  }
}
