import qs from "qs";
const { stringify } = qs;

export default class ArbitrationAPI {
  #baseURL = `${import.meta.env.VITE_HSF_API_BASE_URL}/genesis/arbitration`;
  #ENTITY = "Arbitration";

  constructor(http) {
    this.http = http;
  }

  /**
   * Add a single arbitration to matter groups.
   * @param {Object} data {
            matterGroup: matter group id,
            arbitrationName: arbitration name by user,
        }
   */
  async addArbitration(data) {
    if (!data.matterGroup && !data.genesisName) {
      throw new Exception("Data is invalid");
    }
    const sendData = {
      matterGroup: data.matterGroup,
      arbitrationName: data.genesisName,
    };
    return this.http.post(`${this.#baseURL}/v1/arbitration/`, sendData);
  }

  /**
   * Get the arbitrations lists
   * @param {String} id The Matter group id.
   */
  async getGenesisData(id) {
    return this.http.get(
      `${this.#baseURL}/v1/matters/groups/${id}/arbitrations`
    );
  }

  /**
   * Get the arbitration fields
   * @param {String} arbitrationId The arbitration id.
   */
  async getFields(arbitrationId) {
    return this.http.get(
      `${this.#baseURL}/v1/arbitration/${arbitrationId}/fields`,
      {
        params: {
          CoreFieldType: this.#ENTITY,
        },
      }
    );
  }

  /**
   * PATCH a user's experience record
   * @param {Object} options The object containing params
   * @param {String} options.id The genesis type id to save
   * @param {String} options.path An path path of strings to set
   * @param {Array}  options.values An array of strings to set
   * @returns Promise
   */
  async setGenesisFields({ id, path, values = [] }) {
    if (!id) {
      throw new Error("Arbitration ID is missing from request.");
    }
    const body = [
      {
        op: "replace",
        path: path,
        value: values,
      },
    ];
    return this.http.patch(`${this.#baseURL}/v1/${this.#ENTITY}/${id}`, body);
  }

  /**
   * Get the component List
   */
  async getComponents() {
    return this.http.get(`${this.#baseURL}/v1/arbitration/components`);
  }

  /**
   * Add a single component to arbitration.
   * @param {String} arbitrationId The arbitration id.
   * @param {Object} data {
            componentId: component id,
        }
   */
  async addComponentToGenesis(data, arbitrationId) {
    if (!data.componentId && !arbitrationId) {
      throw new Exception("Data is invalid");
    }
    return this.http.post(
      `${this.#baseURL}/v1/arbitration/${arbitrationId}/components`,
      data
    );
  }

  /**
   * Get the component List
   * @param {String} arbitrationId The arbitration id.
   */
  async getGenesisComponents(arbitrationId) {
    return this.http.get(
      `${this.#baseURL}/v1/arbitration/${arbitrationId}/components`
    );
  }

  /**
   * Get the clients fields
   * @param {String} arbitrationId The id of the arbitration
   * @param {String} entityId The id of that entity
   * @param {String} componentName The name of the component
   * @returns {Promise}
   */
  async getEntityFields(arbitrationId, entityId, componentName) {
    componentName = this.removeExtraChar(componentName);
    const url = `${this.#baseURL}/v1/arbitration/${arbitrationId}/fields`;
    return this.http.get(url, {
      params: {
        CoreFieldType: componentName,
        EntityId: entityId,
      },
    });
  }

  /**
   * Delete the clients fields
   * @param {String} arbitrationId The id of the arbitration
   * @param {String} entityId The id of that entity
   * @returns {Promise}
   */
  async deleteComponent(arbitrationId, entityId, componentName) {
    componentName = this.removeExtraChar(componentName);
    return this.http.delete(
      `${this.#baseURL}/v1/arbitration/${arbitrationId}/${componentName}/${entityId}`
    );
  }

  removeExtraChar(name) {
    return name.replace(/-|\s/g, "");
  }

  /**
   * POST a new user to sharepoint site record
   * @param {String} role An user role to set
   * @param {String} firstName An first name to set
   * @param {String} lastName An last name to set
   * @returns Promise
   */
  async addNewSharePointUser(firstName, lastName, role) {
    if (!role) {
      throw new Error("User role is missing from request.");
    }
    const body = {
      firstName: firstName,
      lastName: lastName,
      role: role,
    };
    return this.http.post(`${this.#baseURL}/v1/arbitration/sp/role`, body);
  }

  /**
   * POST a new single select choice
   * @param {Number} LookupId to set
   * @param {String} LookupValue An new choice to set
   * @returns Promise
   */
  async addNewSelectChoice(LookupId, LookupValue) {
    if (!LookupId) {
      throw new Error("Lookup Id is missing from request.");
    }
    const body = {
      LookupValue: LookupValue,
    };
    return this.http.post(`${this.#baseURL}/v1/lookup/${LookupId}`, body);
  }

  /**
   * Search only active and open client code or client name for clients
   * @param {String} phrase The term to search for
   * @param {Boolean} onlyActive get active or all clients
   */
  async search(phrase, engine, onlyActive = false) {
    return this.http.post(
      "/v1/find",
      { cgn: { active: onlyActive } },
      {
        params: {
          q: phrase.trim(),
          engines: engine,
          limit: 50,
          offset: 0,
        },
        paramsSerializer: (params) =>
          stringify(params, { arrayFormat: "repeat" }),
      }
    );
  }

  /**
   * Remove a matter group arbitration data
   * @param {Number} id The id of the matter group
   * @returns Promise
   */
  async deleteGroupData(id) {
    if (!id || typeof id !== "string") {
      throw new Exception("Cannot delete invalid group id.");
    }
    return this.http.delete(`${this.#baseURL}/v1/container/${id}`);
  }
}
