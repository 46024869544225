export default class MagnoliaAPI {
  /** The base URL for the API to call */
  #baseUrl = import.meta.env.VITE_MAGNOLIA_API_BASE_URL;

  constructor(http) {
    this.http = http;
  }

  async getTours() {
    return this.http.get(`${this.#baseUrl}/tours`);
  }
}
