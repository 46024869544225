export default [
  {
    path: "/invitation",
    name: "invitation",
    redirect: { name: "invitations-overview" },
  },
  {
    path: "/invitations/overview/",
    name: "invitations-overview",
    component: () =>
      import("@/modules/invitations/_views/InvitationsOverview.vue"),
    meta: {
      title: "Invitations",
      hasSidebar: true,
    },
  },
];
