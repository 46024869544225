import ClientAPI from "../_api/ClientApi.js";
import { parseError } from "@/services/httpResponse.js";

const createStore = (app) => {
  const getters = {
    isCurrentFY(state) {
      return state.selectedFinancialYear?.isCurrent;
    },
    getFYById: (state) => (year) => {
      if (!year) {
        year = state.financialYears[0]?.id;
      }

      return state.financialYears.find(
        (element) => parseInt(element.id) === year
      );
    },
    getClientFinancialYears(state) {
      return state.financialYears;
    },
    getClientFinancialYear(state) {
      return state.selectedFinancialYear?.id;
    },
    getCurrentFinancialYear(state) {
      return state.financialYears.find((fy) => fy.isCurrent);
    },
    getTimekeepers(state) {
      return state.topTimekeepers;
    },
  };

  const actions = {
    /**
     * Reset a matter back to it's original state
     */
    resetClient({ commit }) {
      commit("setError", "");
      commit("setLoading", false);
      commit("setLoadingStats", false);
      commit("setClientCode", "");
      commit("setClientName", "");
      commit("setClientSince", null);
      commit("setLastActivity", null);
      commit("resetClientPeopleDetails");

      ["totalByStatus"].forEach((value) => {
        commit("setClientStats", {
          property: value,
          value: {},
        });
      });

      [
        "billingEntities",
        "mattersByOffice",
        "mattersByPracticeArea",
        "spend",
        "timeBookedByMonth",
        "totalMattersByYear",
        "totalMattersThisFYByStatus",
        "totalHoursBilled",
        "totalNormalisedClientSpend",
        "setPartners",
      ].forEach((value) => {
        commit("setClientStats", {
          property: value,
          value: [],
        });
      });
    },
    /**
     * Load core client details
     * @param {*} param
     * @param {*} id
     */
    async loadClient({ commit, dispatch, state }, id) {
      try {
        commit("setLoading", true);
        commit("setError", "");
        const { data: client } = await new ClientAPI(
          app.config.globalProperties.$http
        )
          .setFinancialYear(state.selectedFinancialYear?.id)
          .getClientById(id);
        commit("setClientCode", client.client.id);
        commit("setClientName", client.client.clientName);
        commit("setClientSince", client.client.created);
        [
          "billingEntities",
          "totalByStatus",
          "totalMattersThisFYByStatus",
          "totalHoursBilled",
          "totalNormalisedClientSpend",
        ].forEach((value) => {
          const v = {
            property: value,
            value: client[value],
          };
          commit("setClientStats", v);
        });

        // Add to recently viewed list
        dispatch(
          "search/addToRecent",
          {
            id: "c" + id,
            type: "client",
            item: {
              title: client.client.clientName,
              linkId: id,
            },
          },
          { root: true }
        );
      } catch (error) {
        const errorMsg = parseError(error);

        commit("setError", errorMsg);
      } finally {
        commit("setLoading", false);
      }
    },
    /**
     * Load core client details
     * @param {*} param
     * @param {*} id
     */
    async lastTimeActivity({ commit, state }, id) {
      try {
        commit("setLastActivityLoading", true);
        commit("setError", "");
        const { data } = await new ClientAPI(app.config.globalProperties.$http)
          .setFinancialYear(state.selectedFinancialYear?.id)
          .getClientLastActivityById(id);
        commit("setLastActivity", data.lastActivity);
      } catch (error) {
        const errorMsg = parseError(error);

        commit("setError", errorMsg);
      } finally {
        commit("setLastActivityLoading", false);
      }
    },
    /**
     * Load in additional statistics
     * @param {*} param
     * @param String id The Client ID
     */
    async loadClientStats({ commit, state }, id) {
      try {
        commit("setLoadingStats", true);
        commit("setError", "");
        const { data: client } = await new ClientAPI(
          app.config.globalProperties.$http
        )
          .setFinancialYear(state.selectedFinancialYear?.id)
          .getClientStatsById(id);

        [
          "mattersByOffice",
          "mattersByPracticeArea",
          "spend",
          "timeBookedByMonth",
          "totalMattersByYear",
        ].forEach((value) => {
          commit("setClientStats", {
            property: value,
            value: client[value],
          });
        });
      } catch (error) {
        const errorMsg = parseError(error);

        commit("setError", errorMsg);
      } finally {
        commit("setLoadingStats", false);
      }
    },
    /**
     * Load Client Relationship Partners
     * @param {*} param
     * @param String id The Client ID
     */
    async loadClientPartners({ commit }, id) {
      try {
        commit("setLoadingPartners", true);
        commit("setPartnerError", "");
        const {
          data: {
            data: { partners, hasTooManyPartners },
          },
        } = await new ClientAPI(
          app.config.globalProperties.$http
        ).getClientPartnersById(id);

        commit("setPartners", partners);
        commit("setTooManyPartners", hasTooManyPartners);
      } catch (error) {
        const errorMsg = parseError(error);

        commit("setPartnerError", errorMsg);
      } finally {
        commit("setLoadingPartners", false);
      }
    },
    /**
     * Load in additional details such as timekeepers
     * @param {*} param
     * @param String id The Client ID
     */
    async loadClientPeopleDetails({ commit }, id) {
      try {
        commit("setIsLoadingPeopleDetails", true);
        const {
          data: { data },
        } = await new ClientAPI(
          app.config.globalProperties.$http
        ).getClientPeople(id);

        commit("setClientPeopleDetails", data);
      } catch (error) {
        commit("setPeopleDetailsError", error);
      } finally {
        commit("setIsLoadingPeopleDetails", false);
      }
    },
    /**
     * Set the financial year
     * @param {*} param
     * @param String year the selected year
     */
    async setFinancialYear({ commit }, year) {
      commit("setFinancialYear", year);
    },
    /**
     * Set the financial year
     * @param {*} param
     * @param String year the selected year
     */
    async setDefaultFinancialYear({ commit }, year) {
      commit("setDefaultFinancialYear", year);
    },
    /**
     * Set the financial years
     * @param {*} param
     * @param Array years Array of financial years
     */
    async setFinancialYears({ commit }, years) {
      commit("setFinancialYears", years);
    },
  };

  const mutations = {
    setIsLoadingPeopleDetails(state, loading) {
      state.isLoadingPeopleDetails = loading;
    },
    setPeopleDetailsError(state, error) {
      state.peopleDetailsError = error;
    },
    setClientPeopleDetails(state, details) {
      state.topTimekeepers = details?.topTimekeepers;
      state.clientResponsiblePartners = details?.clientResponsiblePartners;
      state.timeBooked = details?.timeBooked;
      state.averageStaffCountPerMatter = details?.averageStaffCountPerMatter;
    },
    resetClientPeopleDetails(state) {
      state.topTimekeepers = [];
      state.clientResponsiblePartners = [];
      state.timeBooked = [];
      state.averageStaffCountPerMatter = [];
    },
    setClientCode(state, matterCode) {
      state.clientCode = matterCode;
    },
    setClientName(state, name) {
      state.clientName = name;
    },
    setClientSince(state, timestamp) {
      state.clientSince = timestamp;
    },
    setClientStats(state, payload) {
      state[payload.property] = payload.value;
    },
    setError(state, error) {
      state.errorMsg = error;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setLoadingStats(state, isLoading) {
      state.isLoadingStats = isLoading;
    },
    setFinancialYear(state, year) {
      state.selectedFinancialYear = year;
    },
    setFinancialYears(state, years) {
      state.financialYears = years;
    },
    setDefaultFinancialYear(state, year) {
      state.selectedFinancialYear = year;
      state.defaultedFinancialYear = true;
    },
    setLastActivity(state, activity) {
      state.lastActivity = activity;
    },
    setLastActivityLoading(state, isLastActivityLoading) {
      state.isLastActivityLoading = isLastActivityLoading;
    },
    setPartners(state, partners) {
      state.clientPartners = partners;
    },
    setTooManyPartners(state, hasTooManyPartners) {
      state.clientHasTooManyPartners = hasTooManyPartners;
    },
    setLoadingPartners(state, isLoading) {
      state.isLoadingPartners = isLoading;
    },
    setPartnerError(state, error) {
      state.errorPartners = error;
    },
  };

  const state = {
    clientCode: null,
    clientName: null,
    clientSince: null,
    lastActivity: null,
    clientPartners: [],
    clientHasTooManyPartners: false,
    // Stats
    billingEntities: [],
    mattersByOffice: [],
    mattersByPracticeArea: [],
    spend: [],
    timeBookedByMonth: [],
    totalMattersByYear: [],
    financialYears: [],
    selectedFinancialYear: {},
    defaultedFinancialYear: false,
    totalByStatus: {},
    totalMattersThisFYByStatus: {},
    totalHoursBilled: {},
    totalNormalisedClientSpend: {},
    // Misc
    isLoading: false,
    isLoadingStats: false,
    isLoadingPartners: false,
    errorPartners: "",
    errorMsg: "",
    isLastActivityLoading: false,
    // People details
    isLoadingPeopleDetails: false,
    peopleDetailsError: null,
    topTimekeepers: [],
    clientResponsiblePartners: [],
    timeBooked: [],
    averageStaffCountPerMatter: [],
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
