<template>
  <v-bottom-navigation
    v-model="bottomNav"
    location="bottom"
    color="primary"
    class="menu-buttons"
  >
    <!-- Hidden item to fake the home button with -->
    <v-btn v-show="false" value="1" />

    <v-btn :to="{ name: 'matters' }" value="2">
      <span>Matters</span>
      <v-icon icon="mdi-briefcase" />
    </v-btn>

    <v-btn :to="{ name: 'clients' }" value="3">
      <span>Clients</span>
      <v-icon icon="mdi-account-group" />
    </v-btn>

    <div v-ripple class="home-button-border mt-n7 background accent-darken-1">
      <div
        id="homeButton"
        class="home-button"
        :class="isHomeInverted ? 'secondary' : 'background'"
      >
        <router-link :to="{ name: 'home' }">
          <app-logo
            :width="51"
            class="mx-auto pa-2 my-2"
            :inverted="isHomeInverted"
          />
        </router-link>
      </div>
    </div>
    <!-- Next line is to maintain spacing of tabs at small sizes -->
    <div><v-icon style="visibility: hidden" icon="mdi-book-account" /></div>

    <v-btn :to="{ name: 'people' }" value="4">
      <span>People</span>
      <v-icon icon="mdi-book-account" />
    </v-btn>
    <v-btn v-show="false" value="5" />

    <tools-menu icon-activator menu-up />
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "MobileNavigationA",
  data: () => ({
    bottomNav: "1",
    showTools: false,
  }),
  computed: {
    isHomeInverted() {
      return this.bottomNav === "1";
    },
  },
  watch: {
    "$route.path": function (to) {
      this.setItem(to);
    },
  },
  mounted() {
    this.setItem(this.$route.path);
  },
  methods: {
    setItem(path) {
      let navItem = 1;
      switch (true) {
        case path.startsWith("/matters"):
          navItem = 2;
          break;
        case path.startsWith("/clients"):
          navItem = 3;
          break;
        case path.startsWith("/people"):
          navItem = 4;
          break;
        case path.startsWith("/tools"):
          navItem = 5;
          break;
      }

      this.bottomNav = navItem.toString();
    },
  },
};
</script>

<style type="scss" scoped>
.home-button-border {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  opacity: 0.9;
  position: absolute;
}

.home-button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: #fff;
  margin: -3px auto;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
</style>
