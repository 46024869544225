import SectorAPI from "@/modules/sectors/_api/SectorAPI.js";
const createStore = (app) => {
  const getters = {
    /**
     * Get the set financial year
     * @returns The financial year
     */
    getFinancialYear() {
      return state.financialYear ? state.financialYear.id : state.financialYear;
    },
    getFinancialYears() {
      return state.financialYears;
    },
    /**
     * Is it the current financial year
     * @param {*} state
     * @returns is current year
     */
    isCurrentFY(state) {
      return !state.financialYear || state.financialYear?.isCurrent;
    },
  };

  const actions = {
    /**
     * Get a list of all availabe sectors
     * @param {*} param0 Helper methods
     * @param {*} priorityOnly Only get priority sectors
     */
    async loadSectors({ commit }, priorityOnly = false) {
      try {
        commit("setSectorListLoading", true);
        const {
          data: { data },
        } = await new SectorAPI(app.config.globalProperties.$http).getSectors();
        const sectors = priorityOnly
          ? data.filter((value) => value.onPriorityList)
          : data;
        commit("setSectorList", sectors);
      } catch (error) {
        commit("setSectorListError", error);
      } finally {
        commit("setSectorListLoading", false);
      }
    },

    /**
     * Set the default current financial year
     * @param {*} param0 Helper methods
     * @param {*} year The financial year object
     */
    async setDefaultFinancialYear({ commit, state }, year) {
      if (!state.financialYear) {
        commit("setDefaultedFinancialYear", year);
        commit("setSelectedFinancialYear", year);
      }
    },

    /**
     * Set the current financial year
     * @param {*} param0 Helper methods
     * @param {*} year The financial year object
     */
    async setFinancialYear({ commit }, year) {
      commit("setSelectedFinancialYear", year);
    },

    async setFinancialYears({ commit }, years) {
      commit("setFinancialYears", years);
    },

    async setSectorSlice({ commit }, slice) {
      commit("setSelectedSectorSlice", slice);
    },
  };

  const mutations = {
    /** Sector List */
    setSectorList(state, sectors) {
      state.sectorList = sectors;
    },
    setSectorListLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSectorListError(state, error) {
      state.hasError = error;
    },
    setSelectedSectorSlice(state, slice) {
      state.sectorSlice = slice;
    },
    setFinancialYears(state, fys) {
      state.financialYears = fys;
    },
    /** Financial Years */
    setSelectedFinancialYear(state, fy) {
      state.financialYear = fy;
    },
    setDefaultedFinancialYear(state, fy) {
      state.defaultFinancialYear = fy;
      state.defaultedFinancialYear = true;
    },
  };

  const state = {
    isLoading: false,
    hasError: null,
    sectorList: [],
    showOnlyPriority: true,
    sectorSlice: null,
    financialYear: {},
    financialYears: [],
    defaultedFinancialYear: false,
    defaultFinancialYear: null,
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
