<template>
  <!-- Have to use a div here so <transition> works -->
  <div>
    <!-- If not in AD at all -->
    <v-avatar
      v-if="notFound"
      id="BtnUserInitials"
      color="grey"
      :size="size"
      :class="highlightBorder ? 'border-highlighted' : 'border-normal'"
      :title="email"
      :rounded="tile ? '0' : undefined"
    >
      <span v-if="showInitials" class="text-h6">{{ getUserInitials }}</span>
      <v-icon v-else icon="mdi-account-cancel" />
    </v-avatar>

    <!-- If large -->

    <!-- If in AD... -->
    <v-avatar
      v-else
      id="BtnUserInitials"
      :size="size"
      :class="highlightBorder ? 'border-highlighted' : 'border-normal'"
      :rounded="tile ? '0' : undefined"
      :color="!userPhoto ? 'secondary' : 'transparent'"
    >
      <!-- ...with photo -->
      <transition name="fade">
        <v-img
          v-if="userPhoto"
          class="user-photo"
          :src="userPhoto"
          :alt="getUserInitials"
          :title="userName"
          height="175"
          cover
          :gradient="getGradient"
        >
          <slot />

          <template #placeholder>
            <div class="py-2 text-center">
              <v-progress-circular indeterminate color="accent" />
            </div>
          </template>
        </v-img>
      </transition>

      <!-- ...without photo -->
      <div
        v-if="!userPhoto"
        id="BtnUserInitials"
        data-type="user-initials"
        class="text-white user-initials"
        :class="getFontClass"
        :title="userName"
      >
        {{ getUserInitials }}
      </div>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "UserPhoto",
  props: {
    email: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: undefined,
    },
    size: {
      type: [String, Number],
      default: 96,
    },
    highlightBorder: {
      type: Boolean,
      default: false,
    },
    showInitials: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    /** @deprecated */
    noPresence: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onNoPhoto", "onNoUser"],
  data() {
    return {
      userPhoto: null,
      userName: null,
      presenceStatus: {},
      presenceInterval: null,
      notFound: false,
    };
  },
  computed: {
    getGradient() {
      return this.gradient ? "180deg, rgba(0,0,0,.2) 30%, rgba(0,0,0,0.9)" : "";
    },
    getFontClass() {
      return parseInt(this.size) < 42 ? "text-subtitle-1" : "text-h5";
    },
    getUserInitials() {
      if (!this.userName) {
        return "";
      }

      const nameSections = this.userName.split(" ");
      const numberOfSections = nameSections.length;
      let initials = nameSections[0].substr(0, 1);

      // Get the first letter of the last section.
      if (numberOfSections > 1) {
        initials = initials + nameSections[numberOfSections - 1].substr(0, 1);
      }

      return initials;
    },
  },
  created() {
    this.userName = this.name;
    this.getName();

    if (this.email === this.$auth.getEmailAddress()) {
      this.getUserPhoto("me");
      return;
    }

    this.getUserPhoto(this.email);
  },
  methods: {
    /**
     * Get "me" photo...
     */
    getUserPhoto(who) {
      // This only currently works on the beta endpoint
      let graphURL = `https://graph.microsoft.com/beta/users/${this.email}/photo/$value`;
      if (who === "me") {
        graphURL = "https://graph.microsoft.com/beta/me/photo/$value";
      }
      this.$http
        .get(graphURL, {
          responseType: "blob",
        })
        .then((response) => {
          this.processBlob(response);
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            this.userPhoto = null;
            this.$emit("onNoPhoto");
          }
        });
    },
    /**
     * Handle the response from getPhoto API
     */
    processBlob(response) {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(response.data);
      this.userPhoto = blobUrl;
    },
    /**
     * Get full name details from Graph
     * Useful if we only have an email address
     */
    async getName() {
      try {
        if (!this.email) {
          throw new Error("Cannot fetch name without email address");
        }

        const {
          data: { id, givenName, surname },
        } = await this.$http.get(
          `https://graph.microsoft.com/beta/users/${this.email}?$select=id,givenName,surname`
        );
        this.userName = `${givenName} ${surname}`;
        this.id = id;
      } catch (error) {
        if (error !== "Cannot fetch name without email address") {
          this.notFound = true;
          this.$emit("onNoUser");
        }
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.border-normal {
  border: 2px solid rgb(var(--v-theme-secondary)) !important;
}

.border-highlighted {
  border: 2px dashed rgb(var(--v-theme-warning)) !important;
}
</style>
