<template>
  <!-- Use selected them except when not logged in -->
  <v-theme-provider :theme="isLoggedIn ? selectedTheme : 'customLightTheme'">
    <v-app :key="'ffkey_' + featureFlagsUpdated" class="bg-primary-lighten-2">
      <!-- Add option for totally Chrome-less view that doesn't load anything -->
      <router-view v-if="$route.meta.noShell || isInIgnoreUrls" />

      <!-- Display Main App -->
      <template v-else-if="isLoggedIn">
        <!-- Left Navigation, either fixed on desktop or drawer on mobile -->
        <v-navigation-drawer
          v-model="drawer"
          order="1"
          :floating="!isMobile"
          :permanent="!isMobile"
          class="py-5 px-2 text-white transition-width"
          :color="getNavBarColor"
          :width="getSidebarWidth"
          :disable-resize-watcher="isMobile ? undefined : true"
          :temporary="isMobile"
        >
          <portal-target name="mainMenuPrimary" />
          <portal-target name="mainMenuSecondary" />
        </v-navigation-drawer>

        <!-- Desktop View Top Nav -->
        <app-bar-desktop v-if="!isMobile && !isFromSharepoint" />
        <sharepoint-bar :show-bar="isFromSharepoint" />

        <!-- Mobile View Top Nav-->
        <app-bar-mobile v-if="isMobile && !isFromSharepoint" />

        <!-- Main Body for Routes-->
        <v-main class="pb-15" :class="getNavBarClass">
          <app-body />
        </v-main>

        <!-- Mobile Bottom Nav -->
        <template v-if="isMobile && !isFromSharepoint">
          <mobile-navigation-a v-if="!hasSectorHubAccess" />
          <mobile-navigation-b v-else />
        </template>

        <offline-detection />
      </template>

      <!-- ... but if no AccessToken any more then don't -->
      <login-screen v-else :login-error="loginError" :login-code="loginCode" />
    </v-app>
  </v-theme-provider>
</template>

<script>
import { watch, provide, ref } from "vue";
import { mapState } from "vuex";
import { useDisplay, useTheme } from "vuetify";
import { useHurricaneTheme } from "@/composables/HurricaneTheme.js";
import { USER_ROLE } from "@/config/constants.js";

export default {
  setup() {
    const { mobile: isMobile } = useDisplay();
    const { selectedTheme } = useHurricaneTheme();
    const vuetifyTheme = useTheme();

    const drawer = ref(false);
    provide("menuDrawer", drawer);

    // Once selectedTheme has been changed, update vuetify theme
    watch(selectedTheme, (newValue) => {
      vuetifyTheme.global.name.value = newValue;
    });

    return { isMobile, drawer, selectedTheme };
  },
  data() {
    return {
      isLoggedIn: false,
      loginError: null,
      loginCode: null,
      loginInterval: null,
      isFromSharepoint: false,
    };
  },
  computed: {
    isInIgnoreUrls() {
      return this.$route.name === "loading";
    },
    hasSectorHubAccess() {
      return this.$auth.hasRole(USER_ROLE["sectorHubAccess"]);
    },
    getSidebarWidth() {
      const expandedWidth = 220;
      const minimisedWidth = 80;

      if (typeof this.$route.meta.hasSidebar === "function") {
        const result = this.$route.meta.hasSidebar(this);
        if (typeof result !== "boolean") {
          throw new Error(
            `Sidebar calculation functions must return boolean, got ${typeof result}`
          );
        }

        return result ? expandedWidth : minimisedWidth;
      }

      return this.$route.meta.hasSidebar ? expandedWidth : minimisedWidth;
    },
    getNavBarColor() {
      return this.$vuetify.theme.current.dark ? "primary" : "primary-lighten-2";
    },
    getNavBarClass() {
      return this.$vuetify.theme.current.dark
        ? "bg-primary"
        : "bg-primary-lighten-2";
    },
    ...mapState("core", {
      featureFlagsUpdated: (state) => state.featureFlagsUpdated,
    }),
  },
  watch: {
    // Adding this as disable-resize-watcher doesn't seem to work?
    "$vuetify.display.smAndDown"(to) {
      if (to === true) {
        this.drawer = false;
      }
    },
    "$vuetify.display.mdAndUp"(to) {
      if (to === true) {
        this.drawer = true;
      }
    },
    "$route.path"() {
      this.isLoggedIn = this.$auth.isAuthenticated();
    },
  },
  created() {
    document.addEventListener("visibilitychange", this.checkVisibility());

    if (RegExp(/https:\/\/(.*)\.sharepoint\.com/).exec(document.referrer)) {
      sessionStorage.setItem("fromSharepointLink", document.referrer);
      sessionStorage.setItem("fromSharepointMethod", window.history.length);
    }
  },
  mounted() {
    this.isLoggedIn = this.$auth.isAuthenticated();

    if (this.isLoggedIn) {
      this.$store.dispatch("core/setRouterLoggingIn", false);
    }

    setTimeout(() => {
      this.loginError = this.$route.query.error;
      this.loginCode = this.$route.query.code;
    }, 1000);

    // Handle initial menu state based when re-mounting components
    this.drawer = this.$vuetify.display.mdAndUp === true;
  },
  unmounted() {
    clearInterval(this.loginInterval);
    document.removeEventListener("visibilitychange", this.checkVisibility());
  },
  methods: {
    /**
     * Runs an "isAuth" check and if not set, adds it to a 1m timer
     */
    runSessionCheck() {
      this.isLoggedIn = this.$auth.isAuthenticated();
      if (!this.loginInterval) {
        this.loginInterval = setInterval(() => {
          this.checkSession();
        }, 60000);
      }
    },
    /**
     * When tab visibility changes, start and stop the timer
     */
    checkVisibility() {
      if (document.visibilityState === "visible") {
        this.runSessionCheck()
        return;
      }

      clearInterval(this.loginInterval);
    },
    /**
     * Check if the user is still authenticated
     */
    checkSession() {
      if (!this.$auth.isAuthenticated()) {
         
        console.warn("Authentication Expired.");
        this.isLoggedIn = false;
        clearInterval(this.loginInterval);
        return;
      }

      const expiryDate = this.$auth.getIdToken()?.exp * 1000;
      const expiryTime = expiryDate - Date.now();
      const expInMinutes = Math.floor(expiryTime / 60000);
      const tokenTime = new Date(expiryDate).toLocaleString();
      // eslint-disable-next-line no-console
      console.log(
        `#${this.loginInterval} Token will expire in ${expInMinutes} minutes (${tokenTime})`
      );

      this.isLoggedIn = true;
    },
  },
};
</script>

<style scoped>
.transition-width {
  transition: width 0.2 ease-in-out;
}
</style>
