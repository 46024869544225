const backToMatterButton = {
  name: "Back to Matter",
  to: "view-matter",
};

const matterReportsComponent = () =>
  import("@/modules/matters/_views/MatterReports.vue");

const baseTitle = "Matter Insight // {id}";

export default [
  {
    path: "overview",
    name: "view-matter",
    component: () => import("@/modules/matters/_views/MatterViewFactory.vue"),
    props: true,
    meta: {
      title: baseTitle,
      hasSidebar: true,
      breadcrumb: [],
    },
  },
  // Temporary path to redirect
  {
    path: "report",
    name: "view-matter-report",
    redirect: { name: "view-matter-insight-report" },
  },
  {
    path: "insight-report",
    name: "view-matter-insight-report",
    component: () => import("@/modules/matters/_views/PowerBiReport.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Insight Report",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "matter-insider-report",
    name: "view-matter-insider-report",
    component: () => import("@/modules/matters/_views/MatterInsiderReport.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Insider Report",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "matter-self-maintaining-report",
    name: "view-matter-self-maintaining-report",
    component: () => import("@/modules/matters/_views/MatterInsiderReport.vue"),
    props: (route) => ({ id: route.params.id, selfMaintain: true }),
    meta: {
      title: baseTitle + " // Self-Maintaining Report",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "fees-and-hours",
    name: "view-matter-fees-hours",
    component: () => import("@/modules/matters/_views/MatterFeesAndHours.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Fees & Hours",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "activity",
    name: "view-matter-activity",
    component: () => import("@/modules/matters/_views/MatterActivity.vue"),
    props: true,
    meta: {
      title: baseTitle + " // People",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "estimates/:selectedTab?",
    name: "view-matter-plan-estimates",
    component: () => import("@/modules/matters/_views/MatterEstimates.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Estimates",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "attributes/:section?",
    name: "view-matter-attributes",
    component: () => import("@/modules/matters/_views/MatterAttributes.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Attributes",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "services",
    name: "view-matter-services",
    component: () => import("@/modules/matters/_views/MatterServices.vue"),
    props: true,
    meta: {
      title: baseTitle + " // Services",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "ssrs-reports/",
    name: "matter-ssrs-reports",
    component: matterReportsComponent,
    props: true,
    meta: {
      title: baseTitle + " // Reports",
      hasSidebar: true,
      breadcrumb: [backToMatterButton],
    },
  },
  {
    path: "ssrs-reports/:reportId?",
    name: "matter-ssrs-report",
    component: matterReportsComponent,
    props: true,
    meta: {
      title: baseTitle + " // Reports",
      hasSidebar: true,
      breadcrumb: [
        backToMatterButton,
        {
          name: "Back to Reports",
          to: "matter-ssrs-reports",
        },
      ],
    },
  },
];
