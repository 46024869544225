export default [
  {
    path: "/deadline-calculator",
    redirect: { name: "deadline-calculator" },
  },
  {
    path: "/tools/deadline-calculator",
    name: "deadline-calculator",
    component: () =>
      import("@/modules/deadline-calculator/_views/DeadlineCalculator.vue"),
    meta: {
      title: "Deadline Calculator",
    },
  },
];
