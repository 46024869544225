<template>
  <v-img :src="getAppLogo" :width="width" />
</template>

<script>
import whiteCircleLogo from "@/assets/hsf-circle-white.svg";
import circleLogo from "@/assets/hsf-circle.svg";

export default {
  name: "AppLogo",
  props: {
    width: {
      type: Number,
      default: 64,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getAppLogo() {
      if (this.$vuetify.theme.current.dark || this.inverted) {
        return whiteCircleLogo;
      }

      return circleLogo;
    },
  },
};
</script>
