<template>
  <v-menu
    v-if="requiresServiceWorkerUpgrade"
    v-model="isOpen"
    location="bottom center"
    origin="auto"
    :close-on-content-click="false"
    v-bind="$attrs"
  >
    <template #activator="{ props }">
      <v-btn icon="mdi-reload-alert" color="warning" v-bind="props" />
    </template>
    <v-card>
      <v-card-title>
        <v-icon
          start
          size="small"
          class="mt-n1"
          icon="mdi-reload-alert"
        />
        {{ $t("common.components.reload-site.title") }}
      </v-card-title>
      <v-card-text class="pb-0">
        <i18n-t keypath="common.components.reload-site.body">
          <template #appName>
            <app-name class="d-inline-block" />
          </template>
        </i18n-t>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn variant="text" color="secondary" @click="isOpen = false">
          {{ $t("common.components.reload-site.later") }}
        </v-btn>
        <v-btn
          variant="elevated"
          :loading="isLoading"
          color="warning"
          @click="reloadApplication"
        >
          {{ $t("common.components.reload-site.reload") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReloadSite",
  data: () => ({
    isLoading: false,
    isOpen: false,
  }),
  computed: {
    ...mapState("core", ["requiresServiceWorkerUpgrade"]),
  },
  watch: {
    requiresServiceWorkerUpgrade(to) {
      setTimeout(() => {
        this.isOpen = to;
      }, 500); // delay opening of menu until icon has rendered
    },
  },
  methods: {
    reloadApplication() {
      this.isLoading = true;
      // eslint-disable-next-line no-console
      console.log("App: Message Service Worker to Skip Waiting");
      // Delay the reload slightly to show loader (prove we're doing something)
      // Message SW to say "skipWaiting" and when it does, message us back to reload the window...
      setTimeout(() => {
        this.$updateSW?.();
      }, 1000);

      // ...but if it hasn't done that in 20 seconds, try it less gracefully.
      setTimeout(() => {
        window.location.reload();
      }, 20000);
    },
  },
};
</script>
