<template>
  <v-app-bar v-if="showSharepointBar" color="primary-lighten-2" flat>
    <v-btn variant="flat" color="primary-lighten-1" @click="close">
      <v-icon start :icon="getCloseIcon" />
      {{ getCloseText }}
    </v-btn>
    <v-spacer />
    <v-btn
      variant="flat"
      color="primary-lighten-1"
      @click="continueToHurricane"
    >
      Continue to <app-name class="ml-1" />
      <v-icon end icon="mdi-arrow-right" />
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "SharepointBar",
  emits: ["update:show-bar"],
  data: () => ({
    showSharepointBar: false,
    sharepointUseBackButton: false,
    sharepointUrl: null,
  }),
  computed: {
    getCloseText() {
      return this.sharepointUseBackButton ? "Back to Sharepoint" : "Close";
    },
    getCloseIcon() {
      return this.sharepointUseBackButton ? "mdi-arrow-left" : "mdi-close";
    },
  },
  mounted() {
    const sharepointBar = sessionStorage.getItem("fromSharepointLink");
    const sharepointButton = sessionStorage.getItem("fromSharepointMethod");
    if (sharepointBar) {
      this.showSharepointBar = true;
      this.$emit("update:show-bar", true);
    }
    if (parseInt(sharepointButton) > 1) {
      this.sharepointUseBackButton = true;
      this.sharepointUrl = sharepointBar;
    }
  },
  methods: {
    continueToHurricane() {
      sessionStorage.removeItem("fromSharepointLink");
      sessionStorage.removeItem("fromSharepointMethod");
      this.showSharepointBar = false;
      this.$emit("update:show-bar", false);
    },
    close() {
      if (this.sharepointUseBackButton) {
        const url = new URL(this.sharepointUrl);
        // Define a regex pattern for SharePoint URLs
        if (RegExp(/^https:\/\/(\w+)\.sharepoint\.com\/.*/i).exec(url.href)) {
          window.location.href = `${url.toString()}`;
        }
        return;
      }
      window.close();
    },
  },
};
</script>
