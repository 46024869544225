import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import AuthService from "@/services/authService.js";

const ERROR_NOT_YET_INIT = "AppInsights is not yet initialised.";
let appInsights = {
  setAuthenticatedUserContext() {
    throw new Error(ERROR_NOT_YET_INIT);
  },
  clearAuthenticatedUserContext() {
    throw new Error(ERROR_NOT_YET_INIT);
  },
  trackEvent() {
    throw new Error(ERROR_NOT_YET_INIT);
  },
};

const user = AuthService.isAuthenticated()
  ? AuthService.getEmailAddress()
  : null;

const connectionString = import.meta.env.VITE_INSIGHTS_CONNECTION_STRING;
appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    instrumentationKey: "",
    accountId: user,
    loggingLevelConsole: 1,
    enableAutoRouteTracking: true,
    disableTelemetry: !connectionString,
    disableInstrumentationKeyValidation: !connectionString,
    maxAjaxCallsPerView: 1500,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

appInsights.addTelemetryInitializer((envelope) => {
  const baseData = envelope.baseData;
  const MSGraph =
    baseData.target.includes("https://graph.microsoft.com") &&
    baseData.target.includes("/photo");

  if (MSGraph && baseData?.responseCode && baseData.responseCode === 404) {
    baseData.success = true;
  }

  envelope.tags["ai.application.ver"] = import.meta.env.VITE_VERSION;
});

export default appInsights;
