<template>
  <v-dialog
    v-model="menuOpen"
    :fullscreen="$vuetify.display.mobile"
    :max-width="$vuetify.display.mobile ? '100%' : 400"
  >
    <template #activator="{ props: onMenu }">
      <v-tooltip location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn icon v-bind="{ ...onMenu, ...onTooltip }">
            <user-photo
              :name="$auth.getName()"
              :email="$auth.getEmailAddress()"
              :size="avatarSize"
              :highlight-border="usePreviewMode"
            />
          </v-btn>
        </template>
        {{ getTooltipText }}
      </v-tooltip>
    </template>

    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <img
          alt="Logo"
          :src="getLogo"
          height="16px"
          width="16px"
        >
        <app-name class="text-body-2 font-weight-light" />
        <v-btn
          icon="mdi-close"
          variant="flat"
          size="small"
          @click="menuOpen = false"
        />
      </v-card-title>

      <v-list>
        <v-list-item>
          <template #prepend>
            <user-photo
              :name="$auth.getName()"
              :email="$auth.getEmailAddress()"
              no-presence
              :size="48"
              class="mr-2"
            />
          </template>

          <v-list-item-title>{{ $auth.getName() }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $auth.getEmailAddress() }}
          </v-list-item-subtitle>

          <template #append>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn
                  icon="mdi-logout-variant"
                  :to="{ name: 'logout' }"
                  variant="flat"
                  v-bind="props"
                />
              </template>
              {{ $t("common.core.logout") }}
            </v-tooltip>
          </template>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list class="py-0 rounded-lg">
        <!-- Your Profile (but not if already on it)-->
        <v-list-item
          v-if="!isOnUserProfile"
          :to="{
            name: 'people-profile',
            params: { id: $auth.getEmailAddress() },
          }"
        >
          <template #prepend>
            <v-icon icon="mdi-account" />
          </template>
          <v-list-item-title>
            {{ $t("common.components.user-settings.your-profile") }}
          </v-list-item-title>
        </v-list-item>

        <!-- Settings Page -->
        <v-list-item v-if="!isOnSettingsPage" :to="{ name: 'settings' }">
          <template #prepend>
            <v-icon icon="mdi-cog" />
          </template>
          <v-list-item-title>
            {{ $t("common.components.user-settings.settings") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-row class="mx-3 text-caption py-3">
        <v-col cols="6">
          <v-btn
            v-if="!isOnSystemStatus"
            size="x-small"
            variant="flat"
            append-icon="mdi-chevron-right"
            title="Advanced"
            :to="{ name: 'system-status' }"
          >
            {{ $t("common.components.user-settings.status") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <!-- GDW Status -->
          <v-chip
            v-if="getDataSyncStatus"
            size="x-small"
            :color="getDataSyncColor"
          >
            {{ getDataSyncStatus }}
          </v-chip>
        </v-col>

        <v-col v-if="isStatusLoading" cols="12">
          <v-progress-linear indeterminate color="secondary" />
        </v-col>

        <v-col v-else-if="isStatusError" cols="12">
          <error-message
            :error="isStatusError"
            snackbar
            source="User Settings"
          />
        </v-col>

        <template v-else>
          <v-col cols="6" class="text-center py-0">
            <v-icon :color="getNetwork['color']" :icon="getNetwork['icon']" />
            <div class="text-caption">
              {{ $t("common.components.user-settings.network") }}
            </div>
          </v-col>
          <v-col cols="6" class="text-center py-0">
            <v-icon :color="getData['color']" :icon="getData['icon']" />
            <div class="text-caption">
              {{ $t("common.components.user-settings.data") }}
            </div>
          </v-col>
        </template>
      </v-row>

      <v-divider />

      <!-- Version Number -->
      <div class="bg-background text-center text-caption">
        <i18n-t keypath="common.components.user-settings.version">
          <template #appVersion>
            {{ getAppVersion }}
          </template>
        </i18n-t>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { SETTING_PREVIEW_MODE } from "@/config/constants.js";

import appLogo from "@/assets/matter-insight-logo.svg";
import appLogoDark from "@/assets/matter-insight-logo-white.svg";
import { useSettings } from "@/composables/UserSettings.js";

export default {
  props: {
    avatarSize: {
      type: Number,
      default: 48,
    },
  },
  setup() {
    const usePreviewMode = useSettings(SETTING_PREVIEW_MODE, false);
    usePreviewMode.value = !!usePreviewMode.value; // convert to proper boolean from potential string

    return { usePreviewMode };
  },
  data() {
    return {
      menuOpen: false,
      appLogo: null,
      appLogoDark: null,
      appVersion: import.meta.env.VITE_VERSION,
      statusCheckInterval: null,
    };
  },
  computed: {
    ...mapState("core", {
      loading: (state) => state.systemStatus.loading,
      error: (state) => state.systemStatus.error,
      networkStatus: (state) => state.systemStatus.onPremiseConnectionStatus,
      dataStatus: (state) => state.systemStatus.gdwStatus,
    }),
    getAppVersion() {
      let appVersion = this.appVersion;
      // If version is in staging then truncate string
      if (
        this.appVersion !== "Development" &&
        this.appVersion.charAt(0) !== "v"
      ) {
        // Split commit at - index
        const split = this.appVersion.split("-");
        // Get first 7 characters of commit id and join with second half of id
        appVersion = split[0].slice(0, 7) + `-${split[1]}`;
      }

      return appVersion;
    },
    getTooltipText() {
      return this.usePreviewMode ? "Preview Mode" : "User Settings";
    },
    isOnSettingsPage() {
      return this.$route.name === "settings";
    },
    isOnUserProfile() {
      return (
        this.$route.name === "people-profile" &&
        this.$route.params.id === this.$auth.getEmailAddress()
      );
    },
    isOnSystemStatus() {
      return this.$route.name === "system-status";
    },
    isStatusLoading() {
      return this.loading;
    },
    isStatusError() {
      return this.error;
    },
    getDataSyncStatus() {
      return this.dataStatus.status ?? false;
    },
    getDataSyncColor() {
      return this.dataStatus.status === "Globally Synchronised"
        ? "green"
        : "red";
    },
    getData() {
      if (!this.dataStatus.getData) {
        return { icon: "", color: "" };
      }

      const isSlow = this.dataStatus.getData.speed === "Slow";

      return {
        icon: isSlow ? "mdi-speedometer-slow" : "mdi-speedometer",
        color: isSlow ? "red" : "green",
      };
    },
    getNetwork() {
      if (!this.networkStatus.getData) {
        return { icon: "", color: "" };
      }

      const isSlow = this.networkStatus.getData.speed === "Slow";

      return {
        icon: isSlow ? "mdi-speedometer-slow" : "mdi-speedometer",
        color: isSlow ? "red" : "green",
      };
    },
    getLogo() {
      return this.$vuetify.theme.current.dark ? this.appLogoDark : this.appLogo;
    },
  },
  watch: {
    "$route.path"() {
      this.menuOpen = false;
    },
  },
  created() {
    this.appLogo = appLogo;
    this.appLogoDark = appLogoDark;

    this.getSystemStatus();
  },
  methods: {
    /**
     * Update the state store's knowledge about system status.
     * Do this on a timer otherwise the messages will never disappear.
     */
    getSystemStatus() {
      if (this.$auth.isAuthenticated()) {
        const FIFTEEN_MINUTES = 900000;
        clearInterval(this.statusCheckInterval);
        this.$store.dispatch("core/getSystemStatus");

        this.statusCheckInterval = setInterval(
          function () {
            this.getSystemStatus();
          }.bind(this),
          FIFTEEN_MINUTES
        );
      }
    },
  },
};
</script>
