import SearchAPI from "../_api/searchApi.js";
import { LOCALSTORAGEKEY_RECENTLY_VIEWED_ENTITIES } from "@/config/constants.js";

const createStore = (app) => {
  const getters = {};

  const actions = {
    /** Run the search  */
    async runSearch({ state, commit }, { term, engines, http }) {
      commit("setLoading", true);
      try {
        const searchTime = Date.now();
        let {
          data: { data: results },
        } = await new SearchAPI(http).searchByPhrase(term, engines);
        // Only update the search if another search hasn't run in the meantime
        if (searchTime > state.lastSearchTime) {
          commit("setLastSearchTime", searchTime);
          results = results.sort((a, b) => b.engineRank - a.engineRank);
          commit("setSearchTerm", term);
          commit("setItems", results);
        }
      } catch (error) {
        commit("setError", error);
      } finally {
        commit("setLoading", false);
      }
    },
    getTypes({ commit }, { http }) {
      new SearchAPI(http).getTypes().then((response) => {
        const engines = response.data?.data;
        commit("setAvailableEngines", engines ?? []);
      });
    },
    /**
     * Add item to "recently viewed" stack
     * @param {*} param
     * @param Object item The item to add
     * { id: "", item: {linkId: "", title: "", subtitle: ""}, type: ""}
     */
    async addToRecent({ commit, state }, item) {
      let items = [...state.recentItems];
      // If item is already in the list, then just move it to the top
      const found = items.findIndex((element) => element.id === item.id);

      if (found !== -1) {
        items.splice(found, 1);
      }

      items = items.slice(0, 9);
      // place new item at start of array after taking the first 9 items
      // so we always have 10
      items.unshift(item);
      commit("updateRecentItems", items);
      localStorage.setItem(
        LOCALSTORAGEKEY_RECENTLY_VIEWED_ENTITIES,
        JSON.stringify(items)
      );
    },
    /**
     * Reset store back to base state
     * @param {*} param
     */
    resetSearch({ commit }) {
      commit("setSearchTerm", "");
      commit("setItems", []);
      commit("setError", null);
      commit("setLoading", false);
    },
  };

  const mutations = {
    setLoading(state, isLoading) {
      state.isSearching = isLoading;
    },
    setSearchTerm(state, term) {
      state.searchTerm = term;
    },
    setItems(state, items) {
      state.results = items;
    },
    setError(state, error) {
      state.searchError = error;
    },
    updateRecentItems(state, items) {
      state.recentItems = items;
    },
    setLastSearchTime(state, time) {
      state.lastSearchTime = time;
    },
    setAvailableEngines(state, engines) {
      state.availableEngines = engines;
    },
  };

  const state = {
    searchTerm: "",
    availableEngines: [],
    results: [],
    isSearching: false,
    searchError: null,
    recentItems: [],
    lastSearchTime: null,
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
