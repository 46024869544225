// Regular Expressions
export const RE_MATTER_CODE = /^[0-9-]{1,12}$/;

// Lookup Table IDs
export const REFERENCE_TABLE_IDS = {
  sectors: 10,
  skills: 11,
  experience: 9,
  futureSectors: 8,
};

// User Regions
export const USER_REGION = {
  ocea: "OCEA",
};

// Priority sectors which are available on Sector Hub
export const PRIORITY_SECTORS = [
  "BNK",
  "CON",
  "ERG",
  "INF",
  "MIN",
  "PHA",
  "RES",
  "TMT",
];

// Permission roles
export const USER_ROLE = {
  wam: "People.WorkAllocationManager",
  sectorAdmin: "Sector.Admin",
  partner: "People.Partner",
  terminologyAdmin: "Terminology.Admin",
  insiderReport: "Matter.Insider.User",
  sectorHubAccess: "SectorHub.Access",
};

// Statistical Trend values
export const TREND_LOWER = "lower";
export const TREND_HIGHER = "higher";
export const TREND_EQUAL = "equal";

// Work Allocation Skill Groups
export const SKILL_GROUPS = [
  "corporate",
  "finance",
  "disputes",
  "information technology",
  "real estate",
];

// Trend summary number formats
export const TREND_SUMMARY_NUM_FORMAT = ["number", "currency", "percentage"];

// Genesis Types
export const GENESIS_TYPE_ARBITRATION = "arbitration";
export const GENESIS_TYPE_LITIGATION = "litigation";

// @deprecated Settings moved to Settings Composable. LocalStorage is deprecated.
export const LOCALSTORAGEKEY_WEBPUSH = "web-push-enabled";
export const LOCALSTORAGEKEY_OFFER_NOTIFICATIONS =
  "notifications-offered-and-delayed";
export const LOCALSTORAGEKEY_THEME = "settingsHurricaneTheme";
export const LOCALSTORAGEKEY_SETTINGS_PAGE = "settingsSelectedTab";
export const LOCALSTORAGEKEY_HSFAI_ENABLED = "settingsEnableHSFAISidebar";
export const LOCALSTORAGEKEY_CLOUDDATA_ENABLED = "settingsEnableCloudData";
export const LOCALSTORAGEKEY_PREVIEWMODE_ENABLED = "settingsEnablePreviewMode";
export const LOCALSTORAGEKEY_RECENTLY_VIEWED_ENTITIES = "recentlyViewed";

// Settings Keys for IndexedDB settings
export const SETTING_HSFAI_ENABLED = "HSFAI:AcceptedTerms";
export const SETTING_HSFAI_SELECTED_MODEL = "HSFAI:SelectedModel";
export const SETTING_HSFAI_SELECTED_TEMPERATURE = "HSFAI:SelectedTemperature";
export const SETTING_CLOUDDATA_ENABLED = "GLOBAL:enableCloudData";
export const SETTING_THEME = "GLOBAL:theme";
export const SETTING_PREVIEW_MODE = "GLOBAL:enablePreviewMode";
export const SETTING_PAGE = "SETTING:selectedTab";

// @deprecated Other LocalStorage moved to Settings Composable
export const LOCALSTORAGEKEY_MATTER_FAMILY_VIEW = "matter-family-mode";

// Other keys for IndexedDB
export const UI_MATTER_FAMILY_VIEW = "UI:MatterViewFamilyTab";
