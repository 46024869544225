import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import * as AuthConfig from "@/config/auth.js";

const auth = await PublicClientApplication.createPublicClientApplication(
  AuthConfig.AuthenticationOptions
);

/**
 * Handle a token response. It only appears post-login, hence we save it for a while here.
 * ... it's also async so we can't rely on it being there whilst the application is loading.
 */
const initialisationPromise = auth.initialize().then(() => {
  return auth
    .handleRedirectPromise()
    .then((response) => {
      if (response !== null) {
        authService.setState(response.state);
        authService.setIdToken(response.idTokenClaims);
        sessionStorage.setItem("idt", JSON.stringify(response.idTokenClaims));
      }
    })
    .catch((error) => {
      window.location = `/?login=false&error=${encodeURI(error)}&code=${
        error.errorCode
      }`;
    });
});

export function getInitialisationPromise() {
  return initialisationPromise;
}

/**
 * Handle all authentication via abstraction
 */
class AuthService {
  /**
   * An ID token returned from the MSAL promise
   */
  idToken = null;

  /**
   * Store the state from MSAL
   */
  state = null;

  constructor(idToken) {
    this.idToken = idToken;
  }

  /**
   * Return the configured MSAL instance
   */
  getInstance() {
    return auth;
  }

  login(params) {
    // Merge the scopes in the config to one big string for login (but they have to be separate for access_token)
    let scopes = [];
    scopes = scopes
      .concat(AuthConfig.AuthenticationParametersMSGraph.scopes)
      .concat(AuthConfig.AuthenticationParametersHSFAPI.scopes)
      .concat(["offline_access"]);

    const config = {
      scopes: scopes,
    };

    if (params?.target) {
      config["state"] = JSON.stringify({
        redirectUri: params.target,
      });
    }

    return auth.loginRedirect(config);
  }

  /**
   * Get access or refresh tokens
   * @param {*} authenticationParameters A config object to pass into acquireTokenSilent
   * @returns Promise(token, error)
   */
  async renewTokens(authenticationParameters) {
    // Let's try a silent token first, otherwise have a go at a redirect
    return new Promise((resolve, reject) => {
      // Use the default scopes from config if not overridden.
      if (!authenticationParameters) {
        reject(new Error("No Authentication Parameters set"));
      }

      const account = this.getAccount();
      if (!account) {
        reject(
          new Error("Trying to obtain access_token but not logged in >.<")
        );
      }
      authenticationParameters["account"] = account;

      // If we already have a logged in account, use that as a hint to try and
      // work around multiple signed-in accounts causing silentToken failures.
      if (this.getUserName()) {
        authenticationParameters["loginHint"] = this.getUserName();
      }

      auth
        .acquireTokenSilent(authenticationParameters)
        .then((accessTokenResponse) => {
          resolve(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            auth.acquireTokenRedirect(authenticationParameters);
            return;
          }
          reject(new Error(error));
        });
    });
  }

  /**
   * Log user out of MSAL
   */
  logout() {
    sessionStorage.clear();
    auth.logoutRedirect({
      account: this.getAccount(),
      postLogoutRedirectUri: import.meta.env.VITE_AUTH_AZ_LOGOUT_REDIRECT_URL,
      authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
    });
  }

  /**
   * Is the user still authenticated and the token hasn't expired
   */
  isAuthenticated() {
    if (!auth) {
      return false;
    }

    if (this.idToken === null) {
      return false;
    }

    if (this.isGuestUser() && !this.approvedIdp()) {
      return false;
    }

    if (this.idToken.exp * 1000 <= new Date().getTime()) {
      sessionStorage.removeItem("idt");
      return false;
    }

    return true;
  }

  /**
   * Get the ID token from MSAL
   */
  getIdToken() {
    return this.idToken;
  }

  /**
   * Set an ID token into the object post-construction
   * @param Object idToken The ID token from MSAL
   */
  setIdToken(idToken) {
    this.idToken = idToken;
  }

  /**
   * Get the state
   */
  getState() {
    return this.state;
  }

  /**
   * Set the state from the MSAL response
   */
  setState(state) {
    this.state = state;
  }

  /**
   * Get the user object
   */
  getAccount() {
    return auth?.getAccountByUsername(this.getUserName());
  }

  /**
   * Get the user's name as from token. This usually returns in "surname, firstname" format
   * and should not be used for displaying data
   */
  getTokenName() {
    return this.idToken?.name;
  }

  /**
   * Get the user's addressable name
   */
  getName() {
    return `${this.idToken?.given_name?.trim()} ${this.idToken?.family_name?.trim()}`;
  }

  /**
   * Only get the users first name
   */
  getFirstName() {
    return this.idToken?.given_name;
  }

  /**
   * Get the user's initials, limited to two, such as DH
   */
  getInitials() {
    return (
      this.idToken?.given_name.substring(0, 1) +
      this.idToken?.family_name.substring(0, 1)
    );
  }

  /**
   * Return the user's email address, with handling for *.onmicrosoft.com email address mismatching actual addresses.
   */
  getEmailAddress() {
    // Check to see if preferred username has been set and looks a bit like an email address
    const preferredUsername = this.idToken?.preferred_username.toLowerCase();
    if (preferredUsername && preferredUsername.indexOf("@") !== -1) {
      return preferredUsername;
    }

    // ... if not, just use email address field
    return this.idToken?.email.toLowerCase();
  }

  /**
   * Returns the username from the token
   */
  getUserName() {
    return this.idToken?.preferred_username;
  }

  /**
   * Returns the ID of the user in the system
   */
  getUserId() {
    return this.idToken?.oid;
  }

  /**
   * Return boolean if we think user is a guest in the directory
   */
  isGuestUser() {
    const idToken = this.getIdToken();

    return idToken?.idp && idToken["idp"] !== idToken["tid"];
  }

  /**
   * Return approved guest list via idP
   */
  approvedIdp() {
    const idToken = this.getIdToken();

    return idToken?.idp && AuthConfig.ApprovedIdpList.includes(idToken?.idp);
  }

  /**
   * Return the user's roles
   */
  getRoles() {
    return this.idToken?.roles;
  }

  /**
   * Return boolean for role checks in roles array
   */
  hasRole(role) {
    const roles = this.getRoles();
    if (!roles) {
      return false;
    }
    return roles.includes(role);
  }
}

const authService = new AuthService(JSON.parse(sessionStorage.getItem("idt")));

export default authService;
