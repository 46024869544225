<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useDisplay } from "vuetify";
import { useI18n } from "vue-i18n";
import {
  isOnline,
  addEventListeners,
  removeEventListeners,
} from "./navigatorStatus.js";

const { mobile: isMobile } = useDisplay();
const { t } = useI18n({ useScope: "global" });

const snackbarVisible = ref(false);
const snackbarMessage = ref("");
const snackbarColor = ref("success");
const snackbarTimeout = ref(-1);
const onlineStatus = ref(isOnline());

// Methods
const updateOnlineStatus = () => {
  const currentStatus = isOnline();
  // Only show when the status changes. E.G online -> offline
  if (currentStatus !== onlineStatus.value) {
    onlineStatus.value = currentStatus;
    snackbarMessage.value = currentStatus
      ? t("common.core.online-snackbar-text")
      : t("common.core.offline-snackbar-text");
    snackbarColor.value = currentStatus ? "success" : "error";
    snackbarTimeout.value = 3000;
    snackbarVisible.value = true;
  }
};

// Lifecycle Hooks
onMounted(() => {
  addEventListeners(updateOnlineStatus);
  updateOnlineStatus();
});

onBeforeUnmount(() => {
  removeEventListeners(updateOnlineStatus);
});
</script>

<template>
  <v-snackbar
    v-model="snackbarVisible"
    :location="isMobile ? 'bottom' : 'bottom left'"
    :color="snackbarColor"
    :timeout="snackbarTimeout"
  >
    <div class="text-caption">
      {{ snackbarMessage }}
    </div>
  </v-snackbar>
</template>
