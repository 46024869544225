import qs from "qs";
const { stringify } = qs;

export default class SearchAPI {
  constructor(http) {
    this.http = http;
  }

  /**
   * Search for a phrase.
   * @param String phrase The phrase to search for
   * @param Array engines An array of EngineIDs to limit the search results to
   * @param Integer limit The max number of results for each search engine
   * @param Integer offset The offset
   */
  async searchByPhrase(phrase, engines, limit = 5, offset = 0) {
    // Make sure we don't search with old search engines.
    const ignoreEngines = ["OBN"];
    ignoreEngines.forEach((engine) => {
      const index = engines.indexOf(engine);
      if (index !== -1) {
        engines.splice(index, 1);
      }
    });

    return this.http.get("/v1/find", {
      params: {
        q: phrase.trim(),
        engines: engines,
        limit: limit,
        offset: offset,
      },
      paramsSerializer: (params) =>
        stringify(params, { arrayFormat: "repeat" }),
    });
  }

  /**
   * Get the available search engines to use
   */
  getTypes() {
    return this.http.get("/v1/find/types");
  }
}
