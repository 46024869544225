export default [
  {
    path: "/ai",
    component: () => import("@/modules/hsf.ai/_views/ChatShell.vue"),
    redirect: { name: "aiChat" },
    children: [
      {
        path: "chat",
        name: "aiChat",
        component: () => import("@/modules/hsf.ai/_views/ChatGPT.vue"),
        meta: {
          title: "Generative AI",
          noShell: true,
        },
      },
    ],
  },
];
