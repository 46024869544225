import PersonAPI from "../_api/personApi.js";
import BlogsAPI from "../_api/blogsApi.js";

const createStore = (app) => {
  const getters = {
    getStepCount() {
      return state.steps.length;
    },
    getPartnerCapabilityStepCount() {
      return state.partnerCapabilitySteps.length;
    },
  };

  const actions = {
    /**
     * Reset partner capability step
     * @param {*} param0 Helper methods
     */
    async resetPartnerCapabilitySteps({ commit }) {
      commit("setpartnerCapabilityStep", 1);
    },

    async getPersonDetails({ commit }, id) {
      try {
        if (!id) {
          throw new Error("Email Address does not exist");
        }

        commit("setLoading", true);
        const { data: user } = await new PersonAPI(
          app.config.globalProperties.$http
        ).getPerson(id);
        commit("setPerson", user);
      } catch (error) {
        commit("setError", error);
      } finally {
        commit("setLoading", false);
      }
    },
    async getPersonBlogs({ commit }, id) {
      try {
        commit("setBlogsLoading", true);
        const { data } = await new BlogsAPI(
          app.config.globalProperties.$http
        ).getBlogsByUserId(id);
        commit("setBlogs", data);
      } catch (error) {
        commit("setBlogsError", error);
      } finally {
        commit("setBlogsLoading", false);
      }
    },
    resetPerson({ commit }) {
      commit("setError", null);
      commit("setBlogsError", null);
      commit("setPerson", {});
      commit("setBlogs", []);
    },
    async getExistingExperience({ commit }, email) {
      try {
        commit("setLoadingExisting", true);
        const {
          data: { data },
        } = await new PersonAPI(
          app.config.globalProperties.$http
        ).getExistingFoundationExperience(email);

        if (Object.keys(data).length > 0) {
          commit("setExistingExperience", data);
        }
      } catch (error) {
        const {
          response: { data },
        } = error;

        if (error.response?.status !== 404) {
          commit("setExistingError", data?.Error.Message ?? error.message);
        }
      } finally {
        commit("setLoadingExisting", false);
      }
    },
  };

  const mutations = {
    /**
     * Set the current person
     * @param {*} state The state
     * @param {Object} user Object representing the person
     */
    setPerson(state, user) {
      state.person = user;
    },

    /**
     * Set a list of blogs the user has written
     * @param {*} state The state
     * @param {Array} blogs An array of blogs
     */
    setBlogs(state, blogs) {
      state.blogs = blogs;
    },

    /**
     * Set the error message
     * @param {*} state
     * @param {String} error The error message
     */
    setError(state, error) {
      state.error = error;
    },

    /**
     * Set stepper step
     * @param {*} state
     * @param {String} step the step marker
     */
    setStep(state, step) {
      state.step = step;
    },

    /**
     * Set partner capability stepper step
     * @param {*} state
     * @param {String} step the step marker
     */
    setpartnerCapabilityStep(state, step) {
      state.partnerCapabilityStep = step;
    },

    /**
     * Set loading existing experience flag
     * @param {*} state
     * @param {Boolean} isLoading Set existing experience loading flag
     */
    setLoadingExisting(state, isLoading) {
      state.isLoadingExisting = isLoading;
    },

    /**
     * Set existing experience
     * @param {*} state
     * @param {Object} experience Set existing experience
     */
    setExistingExperience(state, experience) {
      state.existingExperience = experience;
    },

    /**
     * Set existing experience error
     * @param {*} state
     * @param {String} error Set error from getting existing experience
     */
    setExistingError(state, error) {
      state.existingError = error;
    },

    /**
     * Set populated mapped experiences
     * @param {*} state
     * @param {Array} experiences array of experience objects
     */
    setSaveExperiences(state, experiences) {
      state.saveExperiences = experiences;
    },

    /**
     * Set empty experience flag
     * @param {*} state
     * @param {Boolean} value The true/false value
     */
    setEmptyExperience(state, value) {
      state.hasEmptyExperience = value;
    },

    /**
     * Set the GET experiences flag
     * @param {*} state
     * @param {Boolean} value The true/false value
     */
    setReloadExperiences(state, value) {
      state.reloadExperiences = value;
    },

    /**
     * Set the loading flags for data
     * @param {*} state
     * @param {Object} type The loading type and state
     */
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },

    /**
     * Set the error message for blogs
     * @param {*} state
     * @param {String} error The error message
     */
    setBlogsError(state, error) {
      state.blogsError = error;
    },

    /**
     * Set the loading flags for blogs
     * @param {*} state
     * @param {Object} type The loading type and state
     */
    setBlogsLoading(state, isLoading) {
      state.isBlogsLoading = isLoading;
    },
  };

  const state = {
    person: {},
    blogs: [],
    isLoading: false,
    error: null,
    blogsError: null,
    isBlogsLoading: false,
    step: 1,
    steps: [
      {
        title: "My Experience",
        component: "StepperExperience",
      },
    ],
    partnerCapabilityStep: 1,
    partnerCapabilitySteps: [
      { title: "Sector" },
      { title: "Work Type" },
      { title: "Region" },
    ],
    isLoadingExisting: false,
    existingExperience: {},
    existingError: null,
    saveExperiences: [],
    hasEmptyExperience: false,
    reloadExperiences: false,
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
