const matterGroupRouteName = "matter-group";
const genesisViewRouteName = "view-genesis";
const baseTitle = "Matter Insight // Genesis";
const backToGroupButton = {
  name: "Back to Container",
  to: matterGroupRouteName,
};
export default [
  {
    path: "/matters/groups/:id/:genesis",
    name: genesisViewRouteName,
    component: () => import("@/modules/genesis/_views/GenesisSectionShell.vue"),
    props: (route) => ({
      id: route.params.id,
      genesis: route.params.genesis,
      mode: route.params.genesis,
    }),
    meta: {
      title: baseTitle,
      hasSidebar: true,
      backButton: backToGroupButton,
      useAPI: "genesis",
      showTitleSection: true,
    },
  },
];
