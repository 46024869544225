const useStrings = () => {
  /**
   * Upper-case the first letter of a string
   * @param {String} text A string to format
   * @returns {String}
   */
  const ucfirst = (text = "") => {
    if (typeof text !== "string") {
      return "";
    }

    return text.charAt(0).toUpperCase() + text.slice(1) || "";
  };

  /**
   * Format a number to look like a currency, with or without a symbol based on config
   * @param {Number} value The number to format
   * @param {Object} config A NumberFormat config object https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @returns {String}
   */
  const currency = (value = 0, config = {}) => {
    if (typeof value !== "number") {
      return "";
    }

    const defaultConfig = {
      maximumFractionDigits: 2,
    };

    const currencyConfig = { ...defaultConfig, ...config };

    return new Intl.NumberFormat(undefined, currencyConfig).format(value);
  };

  /**
   * Decodes character entities in body
   * @param {String} Narrative body string
   * @returns String
   */
  const decodeCharacterEntities = (string) => {
    const text = document.createElement("textarea");
    text.innerHTML = string;

    return text.value;
  };

  /**
   * Escapes a string
   * @param {String} string to escape
   * @returns String
   */
  const escape = (string) => {
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#39;",
    };

    return string.replace(/[&<>"']/g, (m) => map[m]);
  };

  return { ucfirst, currency, decodeCharacterEntities, escape };
};

export { useStrings };
