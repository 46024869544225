import i18n from "../../plugins/i18n";

/**
 * A collection of strategies for handling different types of errors.
 * Each strategy is a function that returns an object with a title and an optional description.
 */
const errorStrategies = {
  /**
   * Constructs a custom error response object.
   * @param {String} title - The localised title for the error.
   * @param {String} description - The localised description for the error.
   * @returns {Object} An object containing the title, description,
   *                   and a service desk information message for the error.
   */
  custom: (title, description) => ({
    title: title,
    description: description,
    serviceDesk: i18n.global.t("error-messages.service-desk"),
  }),

  /**
   * Handles Axios errors.
   * Extracts the HTTP status code and method from the error object to generate a localised error message.
   * @param {Error} error - The Axios error object.
   * @returns {Object} An object containing title and description based on the error's status
   *                   with a service desk message.
   */
  axiosError: (error) => {
    const status = error.request.status;
    const method = error.config.method;
    const keyBase = `error-messages.error-${status}.${method}`;
    return {
      title: i18n.global.t(`${keyBase}.title`),
      description: i18n.global.t("error-messages.axios-description"),
      serviceDesk: i18n.global.t("error-messages.service-desk"),
    };
  },

  /**
   * Handles errors provided as a string.
   * @param {string} error - The error message string.
   * @returns {Object} An object containing the error message as the title.
   */
  stringError: (error) => {
    return {
      title: error,
      description: i18n.global.t("error-messages.default-description"),
      serviceDesk: i18n.global.t("error-messages.service-desk"),
    };
  },

  /**
   * Provides a fallback error message.
   * This is used when the error does not fit any other defined category.
   * @returns {Object} An object containing a default error title.
   */
  fallback: () => ({
    title: i18n.global.t("error-messages.default-title"),
    description: i18n.global.t("error-messages.default-description"),
    serviceDesk: i18n.global.t("error-messages.service-desk"),
  }),
};

// Export the strategies object so it can be used in your Vue component
export default errorStrategies;
