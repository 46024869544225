import axios from "axios";
import httpconfig from "@/config/http.js";
import { authenticationParametersFactory } from "@/config/auth.js";
import { SETTING_CLOUDDATA_ENABLED } from "@/config/constants.js";
import AuthService, {
  getInitialisationPromise,
} from "@/services/authService.js";
import AzureAppInsights from "@/services/azureApplicationInsights.js";
import { get } from "idb-keyval";

const httpService = axios.create(httpconfig);

httpService.interceptors.request.use(
  async (requestConfig) => {
    await getInitialisationPromise();
    if (requestConfig.url.indexOf("https://api.xmltime.com") < 0) {
      requestConfig.headers["X-ApplicationMailMode"] =
        import.meta.env.VITE_MAILCATCHING_HEADER;
    }

    const fakeData = localStorage.getItem("useFakeData");
    if (fakeData && requestConfig.method.toLowerCase() === "get") {
      requestConfig.headers["x-fake-data"] = fakeData;
    }

    get(SETTING_CLOUDDATA_ENABLED)
      .then((isCloudEnabled) => {
        if (isCloudEnabled) {
          requestConfig.headers["x-data-preference"] = isCloudEnabled;
        }
      })
      .catch((error) => {
        console.error("Error fetching Cloud setting:", error);
      });

    // Pick a set of scopes in the config based on the URL of the request
    try {
      const config = authenticationParametersFactory(
        requestConfig.url
      );
      if (Object.keys(config).length === 0) {
        return requestConfig;
      }

      if (config.subscriptionKey) {
        requestConfig.headers["ocp-apim-subscription-key"] =
          config.subscriptionKey;
        return requestConfig;
      }

      const accessToken = await AuthService.renewTokens(config);
      if (accessToken === null) {
        Promise.reject(new Error("Missing access token"));
        return requestConfig;
      }

      requestConfig.headers["Authorization"] = `Bearer ${accessToken}`;

      return requestConfig;
    } catch (error) {
       
      console.error(error);
      if (AzureAppInsights?.trackException) {
        AzureAppInsights.trackException({ exception: new Error(error) });
        return requestConfig;
      }
    }
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default httpService;
