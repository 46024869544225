const matterGroupRouteName = "matter-group";
const matterGroupsRouteName = "matter-groups";
const backToGroupButton = {
  name: "Back to Container",
  to: matterGroupRouteName,
};

const baseTitle = "Matter Insight // Groups";

export default [
  {
    path: "overview/",
    name: matterGroupsRouteName,
    component: () => import("@/modules/matters/_views/MatterGroups.vue"),
    meta: {
      title: "Matter Insight // Groups",
      hasSidebar: true,
      showActionAddGroup: true,
    },
  },
  {
    path: ":id",
    name: matterGroupRouteName,
    component: () => import("@/modules/matters/_views/MatterGroup.vue"),
    props: true,
    meta: {
      title: "Matter Insight // Containers",
      hasSidebar: true,
      backButton: {
        name: "Back to Containers",
        to: matterGroupsRouteName,
      },
      useAPI: "matterGroup",
      showActionGenesis: true,
    },
  },
  {
    path: ":id/report",
    name: "view-matter-groups-report",
    component: () => import("@/modules/matters/_views/PowerBiReport.vue"),
    props: (route) => ({
      id: route.params.id,
      mode: "matterGroup",
    }),
    meta: {
      title: baseTitle + " // Insight Report",
      hasSidebar: true,
      backButton: backToGroupButton,
      showTitleSection: true,
      useAPI: "matterGroup",
    },
  },
  {
    path: ":id/attributes/:section?",
    name: "view-matter-groups-attribute-form",
    component: () => import("@/modules/matters/_views/MatterAttributes.vue"),
    props: (route) => ({
      id: route.params.id,
      section: route.params.section,
      mode: "matterGroup",
    }),
    meta: {
      title: baseTitle + " // Attributes",
      hasSidebar: true,
      backButton: {
        name: "Back",
        to: matterGroupRouteName,
      },
      useAPI: "matterGroup",
      showTitleSection: true,
    },
  },
];
