<template>
  <v-text-field
    ref="searchBox"
    v-model="search"
    placeholder="Search"
    variant="solo"
    density="compact"
    :bg-color="$vuetify.theme.current.dark ? 'background' : null"
    prepend-inner-icon="mdi-magnify"
    clearable
    :disabled="availableEngines.length === 0"
    autocomplete="off"
    :loading="isSearching ? 'accent' : false"
    class="mt-6 ml-2"
    @click:clear="reset"
  >
    <v-menu
      v-model="showResults"
      activator="parent"
      :close-on-content-click="false"
      :open-on-click="!isSearching"
      :model-value="showResults && !isSearching"
      location="bottom"
    >
      <!-- Show all the results from a single engine -->
      <template v-if="showAllResults">
        <v-card max-height="80vh" min-height="40vh" style="overflow-y: auto">
          <v-card-text>
            <v-btn
              size="small"
              prepend-icon="mdi-arrow-left"
              variant="flat"
              @click="backFromFullResults"
            >
              {{ $t("common.components.search.back") }}
            </v-btn>
          </v-card-text>
          <full-search :q="search" :e="showAllResultsEngine" />
        </v-card>
      </template>

      <!-- ... otherwise show default view -->
      <template v-else>
        <!-- If there is a search term then show results...-->
        <v-card
          v-if="search && search.length > 1"
          max-height="80vh"
          style="overflow-y: auto"
        >
          <v-card-text v-if="!hasNoResults">
            <template v-for="(item, index) in results">
              <div
                v-if="getItemLength(item)"
                :key="'more_' + item.engineId"
                class="float-right text-caption mr-3 mt-2"
              >
                <v-btn
                  size="x-small"
                  variant="tonal"
                  color="primary"
                  @click.prevent="goToFullResults(item.engineId)"
                >
                  {{ $t("common.core.show-all") }}
                </v-btn>
              </div>
              <component
                :is="item.engineId.toLowerCase()"
                v-if="getItemLength(item)"
                :key="item.engineId"
                :results="getItemResults(item)"
                dense
              />
              <v-divider
                v-if="index !== results.length - 1 && getItemLength(item)"
                :key="'div_' + item.engineId"
                class="mb-5"
              />
            </template>
          </v-card-text>

          <v-card-text v-else>
            {{ $t("common.components.search.no-results") }}
            <v-chip variant="tonal" color="secondary">
              {{ searchTerm }}
            </v-chip>
          </v-card-text>
        </v-card>

        <!-- If no search term then show the "Recently viewed" list -->
        <v-card v-else>
          <v-card-text>
            <v-list v-if="recentItems.length > 0" lines="two" density="compact">
              <v-list-subheader class="ml-n3">
                {{ $t("common.components.search.recently") }}
              </v-list-subheader>
              <v-list-item
                v-for="item in recentItems"
                :key="item.id"
                :to="getLink(item)"
                :title="item.item.title"
                exact
              >
                <v-list-item-subtitle>
                  {{ item.item.subtitle }}
                  <span v-if="item.type === 'matter'">
                    / {{ item.item.linkId }}
                  </span>
                </v-list-item-subtitle>

                <template #append>
                  <span class="text-caption">
                    {{ ucfirst(item.type) }}
                  </span>
                </template>
              </v-list-item>
            </v-list>
            <div v-else class="text-caption text-center my-5">
              {{ $t("common.components.search.items") }}
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-menu>
  </v-text-field>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";
import SearchEnginesMixin from "@/modules/search/_mixins/SearchEnginesMixin.js";
import { useStrings } from "@/composables/Strings.js";
import { LOCALSTORAGEKEY_RECENTLY_VIEWED_ENTITIES } from "@/config/constants.js";

export default {
  name: "SearchBox",
  components: {
    FullSearch: defineAsyncComponent(
      () => import("@/modules/search/_views/SearchHome.vue")
    ),
  },
  mixins: [SearchEnginesMixin],
  emits: ["search-visible"],
  setup() {
    const { ucfirst } = useStrings();

    return { ucfirst };
  },
  data: () => ({
    search: "",
    showResults: false,
    selectedEngines: [],
    debouncer: null,
    showAllResults: false,
    showAllResultsEngine: null,
  }),
  computed: {
    hasNoResults() {
      return (
        this.searchTerm &&
        this.results.length &&
        this.results.reduce((acc, item) => acc + item.results?.length, 0) === 0
      );
    },
    ...mapState("search", [
      "searchTerm",
      "results",
      "availableEngines",
      "isSearching",
      "recentItems",
    ]),
  },
  watch: {
    /**
     * If the search term changes the run the search
     */
    search(val) {
      if (val && val.length > 0) {
        if (this.showAllResults) {
          this.backFromFullResults();
        }

        this.$store.dispatch("search/resetSearch");

        // If this search term is in the search page query don't show it
        if (this.$route.params.q !== this.search) {
          this.showResults = true;
        }
      }

      if (val) {
        this.runSearch(val);
      }
    },
    /**
     * If the user changes page then reset the search.
     */
    "$route.fullPath": function () {
      this.reset();
    },
    /**
     * If toggle switches change, change which engines searching with
     */
    availableEngines(to) {
      // When engine list is populated, select all of them
      if (to) {
        this.selectedEngines = to.map((x) => x.engineId);
      }
    },
    /**
     * Save the result when selectedEngines changes
     */
    selectedEngines(to) {
      localStorage.setItem("searchEngines", JSON.stringify(to));
    },
    /**
     * Emit an event when the state of the search box changes
     */
    showResults(to) {
      this.$emit("search-visible", to);
    },
  },
  created() {
    this.getFindTypes();

    // Populate recently viewed items from localStorage
    const rv = localStorage.getItem(LOCALSTORAGEKEY_RECENTLY_VIEWED_ENTITIES);
    if (rv) {
      this.$store.commit("search/updateRecentItems", JSON.parse(rv));
    }
  },
  mounted() {
    this.search = this.searchTerm;

    // If on the search page already, pre-populate the box
    if (this.$route.name === "search" && this.$route.params.q) {
      this.search = this.$route.params.q;
    }
  },
  methods: {
    getFindTypes() {
      this.$store.dispatch("search/getTypes", {
        http: this.$http,
      });
    },
    /**
     * Make the search happen with debouncing
     * @param String v The search term
     */
    runSearch(v) {
      if (this.debouncer) {
        clearTimeout(this.debouncer);
      }

      this.debouncer = setTimeout(() => {
        this.$store.dispatch("search/runSearch", {
          term: v,
          engines: this.selectedEngines,
          http: this.$http,
        });
      }, 300);
    },

    /**
     * Get the appropriate link for the history items
     * @param Object item The search term
     */
    getLink(item) {
      switch (item.type) {
        case "matter":
          return { name: "view-matter", params: { id: item.item.linkId } };
        case "matterGroup":
          return { name: "matter-group", params: { id: item.item.linkId } };
        case "person":
          return { name: "people-profile", params: { id: item.item.linkId } };
        case "client":
          return { name: "view-client", params: { id: item.item.linkId } };
        case "genesis":
          return {
            name: "view-genesis",
            params: { id: item.item.linkId, genesis: item.item.genesisType },
          };
      }

      throw new Error(`Link ${item.type} type not yet supported.`);
    },

    /**
     * Revert the form
     */
    reset() {
      this.showResults = false;
      this.search = "";
      this.showAllResults = false;
      this.showAllResultsEngine = null;
      this.$store.dispatch("search/resetSearch");
    },

    /**
     * Expand search into a single engine
     * @param {String} engine The engine ID code
     */
    goToFullResults(engine) {
      this.showAllResults = true;
      this.showAllResultsEngine = engine;
    },

    /**
     * Return to the list of all engines
     */
    backFromFullResults() {
      this.showAllResults = false;
      this.showAllResultsEngine = null;
    },
  },
};
</script>
