import clientViewRoutes from "./clientViewRoutes.js";

export default [
  {
    path: "/clients",
    name: "clients",
    redirect: { name: "my-recent-clients" },
  },
  {
    path: "/clients/recent",
    name: "my-recent-clients",
    component: () => import("@/modules/clients/_views/RecentClients.vue"),
    meta: {
      title: "Client Insight // Recent Clients",
      hasSidebar: true,
    },
  },
  {
    path: "/clients/:id",
    redirect: { name: "view-client" },
    props: true,
    component: () => import("@/modules/clients/_views/ClientShell.vue"),
    children: clientViewRoutes,
  },
];
