const getters = {
  /**
   * Is the param in the matter list?
   * @param String id The matter code
   */
  isInMatterList: (state) => (id) => {
    return (
      state.matters.find((element) => element.matterCode === id) !== undefined
    );
  },
  /**
   * Are any matters in the list marked as secure?
   * @param {*} state
   * @returns boolean
   */
  hasSecureMatters(state) {
    if (!state.matters) {
      return false;
    }
    const secureMatters = state.matters.filter(
      (matter) => matter.secure === true
    );

    return secureMatters.length > 0;
  },
  /**
   * Get a filtered list of matters which respects the "secure" flag.
   */
  filteredMatterList(state) {
    const matters = state.matters.filter((matter) => matter.deleted !== true);

    if (state.hideSecure === false) {
      return matters;
    }

    return matters.filter((matter) => matter.secure === false);
  },
};

const actions = {
  setBackButton({ commit }, path) {
    commit("setNavigation", path);
  },
  /** Set the orderBy field and direction to be used by the `getMatters` action */
  setSortOrder({ commit, state }, sortOrder) {
    if (sortOrder.orderBy) {
      commit("setSortOrderType", sortOrder.orderBy);
    }

    if (sortOrder.direction) {
      commit("setSortOrderDirection", sortOrder.direction);
    }

    // Set the preference per user
    localStorage.setItem(
      "matterSortOrder",
      JSON.stringify({
        orderBy: state.orderBy,
        direction: state.orderByDirection,
      })
    );
  },
  /** Toggle the visibility of secure matters */
  toggleSecure({ commit }, hideSecure) {
    commit("setSecureFlag", hideSecure);
  },
  setReportGenerating({ commit }, value) {
    commit("setReportGenerating", value);
  },
};

const mutations = {
  setSortOrderType(state, sortOrderType) {
    state.orderBy = sortOrderType;
  },
  setSortOrderDirection(state, setSortOrderDirection) {
    state.orderByDirection = setSortOrderDirection;
  },
  setMatters(state, matters) {
    state.matters = matters;
  },
  removeMatter(state, id) {
    const matters = [...state.matters];
    const foundIndex = matters.findIndex(
      (element) => element.matterCode === id
    );

    Object.defineProperty(matters[foundIndex], "deleted", {
      value: true,
      writable: true,
      configurable: true,
    });

    // Have to reassign object based on the initial one for reactivity purposes.
    state.matters = matters;

    state.lastDeleted = id;
  },
  setLoading(state, payload) {
    state[payload.type] = payload.isLoading;
  },
  setError(state, payload) {
    state[payload.type] = payload.error;
  },
  setSecureFlag(state, payload) {
    state.hideSecure = payload;
  },
  setNavigation(state, payload) {
    state.navigationFrom = payload;
  },
  setReportGenerating(state, value) {
    state.reportGenerating = value;
  },
};

const state = {
  // My Matters
  orderBy: "shortName",
  orderByDirection: "asc",
  matters: [],
  hideSecure: false,
  isLoading: false,
  loadingError: "",
  lastDeleted: null,
  navigationFrom: null,
  // Insider Report
  reportGenerating: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
