export default class SectorAPI {
  /** The base URL for the API to call */
  #baseUrl = `${import.meta.env.VITE_HSF_API_BASE_URL}/sectorhub`;

  /** Common validation error message */
  #missingSectorCodeException = "Missing SectorCode from API request";

  /** Set CGP clients flag */
  #cgpClients = undefined;

  /** The selected financial year */
  #selectedFinancialYear = undefined;

  /** The selected sector slice */
  #selectedSectorSlice = undefined;

  /** The selected pagination options */
  #pagination = {};

  /** Set the path option to query sector or sub sector defaults to sector */
  #sectorPath = "sectors";

  constructor(http) {
    this.http = http;
  }

  /**
   * Set the CGP clients flag
   * @param {String} [flag=undefined] Toggle the flag
   * @returns {SectorAPI}
   */
  setCGPClients(flag = undefined) {
    this.#cgpClients = flag;
    return this;
  }

  /**
   * Set the financial year to fetch data for
   * @param {String} [id=undefined] The financial year ID, or the current year if not supplied
   * @returns {SectorAPI}
   */
  setFinancialYear(id = undefined) {
    this.#selectedFinancialYear = id;
    return this;
  }

  /**
   * Set the slice to fetch data for
   * @param {String} [ref=undefined] The sector slice to filter data on (CGP, Partner, All) default of undefined means all
   * @returns {SectorAPI}
   */
  setSectorSlice(ref = undefined) {
    this.#selectedSectorSlice = ref;
    return this;
  }

  /**
   * Set pagination for an API and returns this to chain
   * @param {Object} pagination The pagination object to set
   * @param {Number} [pagination.limit=10] The number of results to fetch
   * @param {Number} [pagination.offset=0] The offset to fetch
   * @returns {SectorAPI}
   */
  setPagination(pagination) {
    const withDefaults = {
      ...{
        limit: 10,
        offset: 0,
      },
      ...pagination,
    };

    this.#pagination = withDefaults;

    return this;
  }

  /**
   * Set the sector path to query sectors or subsectors
   * @param {Boolean} isSubsector
   * @returns {Sector API}
   */
  setSectorPath(isSubsector) {
    this.#sectorPath += isSubsector ? "/subsectors" : "";

    return this;
  }

  /**
   * Get a list of all the sectors
   */
  async getSectors() {
    return this.http.get(`${this.#baseUrl}/v1/sectors`);
  }

  /**
   * Get a single sector by Sector Code
   * @param {String} sectorCode A valid sector code
   * @returns {Promise}
   */
  async getSectorByCode(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(`${this.#baseUrl}/v1/sectors/${sectorCode}`);
  }

  /**
   * Get a the achieved rate for a sector
   * @param {String} sectorCode A valid sector code
   * @returns {Promise}
   */
  async getAchievedRateBySectorCode(sectorCode, isSubSectorView = false) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error("");
    }

    const sectorPath = isSubSectorView ? "sub-sector-rates" : "sector-rates";

    return this.http.get(`${this.#baseUrl}/v1/${sectorPath}/${sectorCode}`, {
      params: {
        fy: this.#selectedFinancialYear,
        "clients-filter-by": this.#selectedSectorSlice,
      },
    });
  }

  /**
   * Get the total matters for a sector
   * @param {String} sectorCode A valid sector code
   * @returns Promise
   */
  async getMattersBySectorCode(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/matters/summary`,
      {
        params: {
          fy: this.#selectedFinancialYear,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      }
    );
  }

  /**
   * Get the total matters By status for a sector
   * @param {String} sectorCode A valid sector code
   * @returns Promise
   */
  async getTotalMattersStatusBySectorCode(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/clients/total-matters/summary`
    );
  }

  /**
   * Get a single sector by ID
   * @param {String} sectorCode A valid sector code
   * @returns {Promise}
   */
  async getRevenueBySectorCode(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/revenue`,
      {
        params: {
          fy: this.#selectedFinancialYear,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      }
    );
  }

  /**
   * Returns a list of revenue groups by HSF office
   * @param {String} sectorCode A valid sector code
   * @returns {Promise}
   */
  async getRevenueByLocation(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/locations/revenue`,
      {
        params: {
          fy: this.#selectedFinancialYear,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      }
    );
  }

  /**
   * Returns a list of revenue grouped by business area, or the revenue for a single practice group
   * @param {String} sectorCode The sector code
   * @param {String} businessArea The business Area
   * @param {String} [practiceGroup=""] The practice group name
   * @returns {Promise}
   */
  async getRevenueByBusinessArea(sectorCode, businessArea, practiceGroup = "") {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }
    if (["practiceGroups", "departments"].indexOf(businessArea) === -1) {
      throw new Error(
        "`businessArea` must be either 'practiceGroups' or 'department'"
      );
    }
    const baseURL = `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}`;

    if (businessArea === "practiceGroups") {
      const pg = practiceGroup ? `/${practiceGroup}/` : "/";

      return this.http.get(`${baseURL}/practice-groups${pg}revenue`, {
        params: {
          fy: this.#selectedFinancialYear,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      });
    }

    return this.http.get(`${baseURL}/departments/revenue`, {
      params: {
        fy: this.#selectedFinancialYear,
        "clients-filter-by": this.#selectedSectorSlice,
      },
    });
  }

  /**
   * Returns a list of revenue grouped by matter type, or the revenue for a matter
   * @param {String} sectorCode The sector code
   * @param {String} [matterType=""] The matter type name
   * @returns {Promise}
   */
  async getRevenueByMatterType(sectorCode, matterType = "") {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const baseURL = `${this.#baseUrl}/v1/${this.#sectorPath}`;

    const pg = matterType ? `/${matterType}/` : "/";

    return this.http.get(`${baseURL}/${sectorCode}/mattertype${pg}revenue`, {
      params: {
        fy: this.#selectedFinancialYear,
        "clients-filter-by": this.#selectedSectorSlice,
      },
    });
  }

  /**
   * Get a list of revenue per month, ordered by month
   * @param {String} sectorCode The sector code
   * @returns {Promise}
   */
  async getRevenueByMonth(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const baseURL = `${this.#baseUrl}/v1/${this.#sectorPath}`;

    return this.http.get(`${baseURL}/${sectorCode}/months/revenue`, {
      params: {
        fy: this.#selectedFinancialYear,
        "clients-filter-by": this.#selectedSectorSlice,
      },
    });
  }

  /**
   * Get the Top 10 Timekeepers based on the parameter passed
   * @param {String} sectorCode The sector code to get timekeepers for
   * @param {String} [sortBy] The column to sort on
   * @param {String} [sortDirection="desc"] The direction of the sort
   * @returns {Promise}
   */
  async getTopTimekeepersInSector(
    sectorCode,
    sortBy = "",
    sortDirection = "desc"
  ) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const validSortOptions = [
      "billedamount",
      "billedhours",
      "billablehours",
      "nonbillablehours",
      "achievedrate",
    ];

    if (sortBy && validSortOptions.indexOf(sortBy) === -1) {
      throw new Error(
        "Sort parameter must be one of: " + validSortOptions(", ")
      );
    }

    const sort = sortBy ? `${sortBy} ${sortDirection}` : "";

    return this.http.get(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/time-keepers`,
      {
        params: {
          limit: 10,
          order: sort,
          fy: this.#selectedFinancialYear,
        },
      }
    );
  }

  /**
   * Get a list of Clients in the sector, ordered by recent matter activity
   * @param {String} sectorCode The sector code
   * @returns {Promise}
   */
  async getClientsInSector(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/clients/matters/summary`,
      {
        params: {
          fy: this.#selectedFinancialYear,
          "include-more-details": true,
          "clients-filter-by": this.#selectedSectorSlice,
          limit: this.#pagination.limit,
          offset: this.#pagination.offset,
        },
      }
    );
  }

  /**
   * Get a list of clients sorted by the revenue they made this year
   * @param {String} sectorCode The sector code
   * @param {Number} [limit=10] Limit the number of results
   * @returns {Promise}
   */
  async getClientsByRevenue(sectorCode, limit = 10) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/clients/revenue`,
      {
        params: {
          cgpclients: this.#cgpClients,
          limit: limit,
          fy: this.#selectedFinancialYear,
          "include-more-details": true,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      }
    );
  }

  /**
   * Get non-billable hours for a sector
   * @param {String} sectorCode The sector code
   * @param {Number} [limit=10] Limit the number of results
   * @returns {Promise}
   */
  async getNonBillableHours(sectorCode, limit = 10) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/non-billable-hours`,
      {
        params: {
          limit: limit,
          fy: this.#selectedFinancialYear,
          "clients-filter-by": this.#selectedSectorSlice,
        },
      }
    );
  }

  /**
   * Get non-billable hours for a client
   * @param {String} sectorCode The sector code
   * @param {String} nonBillableType The Non-Billable Type
   * @param {String} [includeDomain=false] Include fetching the client domain from interaction
   * @returns {Promise}
   */
  getNonBillableHoursForClient(
    sectorCode,
    nonBillableType,
    includeDomain = false
  ) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      "include-domain": includeDomain,
      "clients-filter-by": this.#selectedSectorSlice,
      limit: this.#pagination.limit,
      offset: this.#pagination.offset,
    };

    // encoding non billable type as few types includes '/' in it
    // It needs double-encoding to work around https://github.com/Azure/azure-functions-host/issues/3998
    const nonBillableTypeEncoded = encodeURIComponent(
      encodeURIComponent(nonBillableType)
    );

    return this.http.get(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/non-billable-hours/${nonBillableTypeEncoded}`,
      { params: params }
    );
  }

  /**
   * Get newest clients for a sector
   * @param {String} sectorCode The sector code
   * @param {Boolean} [includeMoreDetails=true]
   * @returns {Promise}
   */
  getNewestClientsInSector(sectorCode, includeMoreDetails = true) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      "include-more-details": includeMoreDetails,
      "clients-filter-by": this.#selectedSectorSlice,
      limit: this.#pagination.limit,
      offset: this.#pagination.offset,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/clients/newest`,
      { params: params }
    );
  }

  /**
   * Get newest clients for a sector
   * @param {String} sectorCode The sector code
   * @returns {Promise}
   */
  getFastestGrowingClientInSector(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      limit: this.#pagination.limit,
      "clients-filter-by": this.#selectedSectorSlice,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${sectorCode}/clients/fastest-growing`,
      { params: params }
    );
  }

  /**
   * Get the Sector Leads for a Sector
   * @param {String} sectorCode The sector code
   * @returns {Promise}
   */
  getSectorLeads(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    return this.http.get(`${this.#baseUrl}/v1/sectors/${sectorCode}/people`);
  }

  /**
   * Get the Sector timekeepers for a Sector
   * @param {String} sectorCode The sector code
   * @returns {Promise}
   */
  getSectorTimekeepers(sectorCode) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      limit: this.#pagination.limit,
      offset: this.#pagination.offset,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/people-list`,
      { params: params }
    );
  }

  /**
   * Add Sector Lead to Sector
   * @param {String} sectorCode The Sector Code
   * @param {String} email The user's email address
   * @param {String} role The role to set them as within the sector
   * @returns {Promise}
   */
  setSectorLead(sectorCode, email, role) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    if (!email && typeof email !== "string") {
      throw new Error("No email address specified");
    }

    const validRoles = ["BusinessDevelopment", "Partner"];
    if (!role && validRoles.indexOf(role) === -1) {
      throw new Error("Role must be one of: " + validRoles.join(", "));
    }

    return this.http.post(`${this.#baseUrl}/v1/sectors/${sectorCode}/people`, {
      email: email,
      roleName: role,
    });
  }

  /**
   * Delete Sector Lead from Sector
   * @param {String} sectorCode The Sector Code
   * @param {Number} leadId The Lead ID to delete
   * @returns {Promise}
   */
  deleteSectorLead(sectorCode, leadId) {
    if (!sectorCode && typeof sectorCode !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    if (!leadId && typeof leadId !== "number") {
      throw new Error("Lead ID must be a number.");
    }

    return this.http.delete(
      `${this.#baseUrl}/v1/sectors/${sectorCode}/people/lead/${leadId}`
    );
  }

  /**
   * Get the contribution margin for given sector/subSector code
   * @param {String} code A valid sector/subSector code
   * @returns {Promise}
   */
  async getContributionMarginByCode(code) {
    if (!code && typeof code !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      "clients-filter-by": this.#selectedSectorSlice,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${code}/kpi/contribution-margin`,
      { params: params }
    );
  }

  /**
   * Get the fee recovery for given sector/subSector code
   * @param {String} code A valid sector/subSector code
   * @returns {Promise}
   */
  async getFeeRecoveryByCode(code) {
    if (!code && typeof code !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      "clients-filter-by": this.#selectedSectorSlice,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${code}/kpi/fee-recovery`,
      { params: params }
    );
  }

  /**
   * Get the year on year growth % for given sector
   * subSector code
   * @param {String} code A valid sector/subSector code
   * @returns {Promise}
   */
  async getYoYGrowthByCode(code) {
    if (!code && typeof code !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const params = {
      fy: this.#selectedFinancialYear,
      "clients-filter-by": this.#selectedSectorSlice,
    };

    return this.http.get(
      `${this.#baseUrl}/v1/${this.#sectorPath}/${code}/kpi/year-on-year-growth`,
      { params: params }
    );
  }

  /**
   * Get sector experiences
   * @param {String} code A valid sector/subSector code
   * @param {Array} filter Country list to filter by
   * @returns {Promise}
   */
  async getSectorExperiences(code, filter = []) {
    if (!code && typeof code !== "string") {
      throw new Error(this.#missingSectorCodeException);
    }

    const httpUrl = `${this.#baseUrl}/v2/${this.#sectorPath}/${code}/matters/experiences`;

    let params = {
      limit: this.#pagination.limit,
      offset: this.#pagination.offset,
      fy: this.#selectedFinancialYear,
      order: "~openDate",
    };

    if (filter?.country?.length > 0) {
      params = {
        ...params,
        "country-filter-by": filter.country.toString(),
      };
    }

    return this.http.get(httpUrl, {
      params: params,
    });
  }

  /**
   * Generates an export file in foundation and returns the file Id
   * @param {Array} matterIds Collection of id's required for export
   * @returns {Promise}
   */
  async getFoundationMatterFileId(matterIds) {
    if (!Array.isArray(matterIds)) {
      throw new Error(
        "`matterIds` must be an array of integers representing ids"
      );
    }
    return this.http.post(
      `${this.#baseUrl}/v1/matters/short-experiences/export/word`,
      matterIds
    );
  }

  /**
   * Get worktypes
   * @returns {Promise}
   */
  async getWorkTypes() {
    const params = {
      "include-disabled": false,
    };

    return this.http.get(`${this.#baseUrl}/v1/worktypes`, {
      params: params,
    });
  }
  /**
   * Return a list of narratives/credentials for a matter
   * @param {String} id The matter code
   * @param {Boolean} useCurrentFy This is the variable to set if data is needed to current financial year or all data
   * @returns Promise
   */
  async getMatterCredentials(id, useCurrentFy = false) {
    if (!id) {
      throw new Error("Cannot load matter credentials without id");
    }
    return this.http.get(
      `${this.#baseUrl}/v1/matters/${id}/credentials?default-to-current-fy=${useCurrentFy}`
    );
  }
}
