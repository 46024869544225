export default {
  /**
   * Global function to check for internet explorer
   */
  isBrowserIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    return msie > 0 || !!RegExp(/Trident.*rv:11\./).exec(navigator.userAgent);
  },
};
