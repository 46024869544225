export default [
  {
    path: "/tools/hss/",
    name: "hss",
    props: true,
    component: () => import("@/modules/hss/_views/HssDashboard.vue"),
    meta: {
      title: "Historic Shortfall Scheme",
    },
  },
];
