import MatterViewRoutes from "./matterViewRoutes.js";
import MatterGroupsRoutes from "./matterGroupsRoutes.js";

export default [
  {
    path: "/matters",
    redirect: { name: "matters" },
  },
  {
    path: "/matters/dashboard",
    name: "matters",
    component: () => import("@/modules/matters/_views/MatterDashboard.vue"),
    meta: {
      title: "Matter Insight",
      hasSidebar: true,
    },
  },
  {
    path: "/matters/insider/reports",
    name: "insider-dashboard",
    component: () => import("@/modules/matters/_views/InsiderDashboard.vue"),
    props: true,
    meta: {
      title: "Insider Reports",
      backButton: {
        name: "My Matters",
        to: "matters",
      },
      hasSidebar: true,
    },
  },
  {
    path: "/matters/groups/",
    redirect: { name: "matter-groups" },
    props: true,
    component: () => import("@/modules/matters/_views/MatterGroupShell.vue"),
    children: MatterGroupsRoutes,
  },
  {
    path: "/matters/:id",
    redirect: { name: "view-matter" },
    props: true,
    component: () => import("@/modules/matters/_views/MatterShell.vue"),
    children: MatterViewRoutes,
  },
];
