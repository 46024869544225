const CacheService = {
  /**
   * Clear a specific browser cache
   * @param {String} cacheName The name of the cache store
   * @param {Request|String} url The URL to match for clearing
   * @param {Object} options An object of cache options as specified https://developer.mozilla.org/en-US/docs/Web/API/Cache/delete#parameters
   * @throws Error on missing params or unsupported API in browser
   * @returns Promise has cache been found + deleted?
   */
  async clear(cacheName, url, options) {
    if (!cacheName) {
      throw new Error("Cannot clear a cache without specifying a cache name.");
    }

    if (!url) {
      throw new Error("URL parameter is needed to clear a cache.");
    }

    if (!("caches" in self)) {
      throw new Error("Browser does not support cache. Please upgrade.");
    }

    const cache = await caches.open(cacheName);
    const path = this.formatPath(
      url,
      `${import.meta.env.VITE_HSF_API_BASE_URL}/hurricane/`
    );

    return cache.delete(path, options);
  },

  /**
   * This block handles if the user only passes in a relative path by adding the base path back in where appropriate
   * @param {String} url The potential URL to format
   * @param {String} [defaultPath=null] Provide a default path to check against
   * @returns {String} The formatted URL
   */
  formatPath(url, defaultPath = null) {
    let path = url;
    // If the default path is set but the path isn't the api.hsf.com base url...
    if (
      defaultPath &&
      !url.startsWith(defaultPath) &&
      !url.startsWith("http")
    ) {
      // ...then, remove leading slash from path if present
      if (url.startsWith("/")) {
        path = path.substring(1);
      }
      // ... add in the base URL to the start of the path
      path = `${defaultPath}${path}`;
    }

    return path;
  },
};

export default CacheService;
