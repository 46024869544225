import { registerSW } from "virtual:pwa-register";

export default {
  install(app) {
    let updateSW = null;
    const store = app.config.globalProperties.$store;

    if ("serviceWorker" in navigator) {
      let pwa_enabled = false;
      // Test to see if key exists in config, otherwise disable
      try {
        pwa_enabled = JSON.parse(import.meta.env.VITE_PWA_DEV_ENABLED);
      } catch (error) {
        console.error(error);
        pwa_enabled = false;
      }

      // If enabled on localhost, or not on localhost, turn on the service worker
      if (pwa_enabled || !/localhost/.test(window.location.host)) {
        updateSW = registerSW({
          onNeedRefresh: () => {
            // eslint-disable-next-line no-console
            console.log("APP: Service Worker - Refresh Needed.");
            setTimeout(() => {
              store.dispatch("core/setServiceWorkerUpgrade", true);
            }, 5000); // wait 5 seconds before triggering the reload request; give APIs time to complete
          },
          onRegisteredSW: (r) => {
            // eslint-disable-next-line no-console
            console.log("APP: Service Worker - Registered.");
            r.update();
          },
          onOfflineReady: () => {
            // eslint-disable-next-line no-console
            console.log("APP: Service Worker - Offline Ready.");
          },
        });

        // Handle reload messages returned from the Service Worker
        navigator.serviceWorker.addEventListener("message", (e) => {
          if (e.data.type === "RELOAD_WINDOW") {
            // eslint-disable-next-line no-console
            console.log("APP: Reload Window Received");
            window.location.reload();
          }
        });

        // Reload app when version changes
        const currentVersion = localStorage.getItem("v");
        if (
          !currentVersion ||
          currentVersion !== import.meta.env.VITE_VERSION
        ) {
          // eslint-disable-next-line no-console
          console.log(
            `Version upgrade: ${currentVersion} > ${
              import.meta.env.VITE_VERSION
            }`
          );
          localStorage.setItem("v", import.meta.env.VITE_VERSION);

          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.map((r) => {
              // eslint-disable-next-line no-console
              console.log("APP: Unregistering current service worker", r);
              r.unregister();
            });
          });
        }
      }
    }

    app.config.globalProperties.$updateSW = updateSW ?? (() => {});
  },
};
