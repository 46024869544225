const createStore = (app) => {
  const getters = {
    getNetworkSpeed() {
      return state.systemStatus.onPremiseConnectionStatus?.getData?.speed;
    },
    getDataSpeed() {
      return state.systemStatus.gdwStatus?.getData?.speed;
    },
    getDataAvailability() {
      // Give it the benefit of the doubt
      if (state.systemStatus.loading) {
        return true;
      }

      return state.systemStatus.gdwStatus?.available;
    },
    /**
     * SystemStatus PBI Dataset comes in multi-dimensional array grouped by ClientGroup.
     * This flattens it down.
     */
    getPBIDataset() {
      const powerBIStatus = state.systemStatus.powerBIStatus;
      if (!powerBIStatus?.datasetStatus) {
        return [];
      }
      const flattened = powerBIStatus.datasetStatus.flatMap(
        (x, index, array) => {
          const sets = [...x.datasets];
          sets.forEach((element) => {
            element["groupName"] = array[index].groupName;
          });

          return sets;
        }
      );

      flattened.sort((a, b) => {
        return new Date(b.finished) - new Date(a.finished);
      });

      return flattened;
    },
    /**
     * Get the previous route to navigate back
     * @param {*} state
     * @returns The previous route object
     */
    getPreviousRoute(state) {
      const historyLen = state.routerHistory.length;
      if (historyLen < 2) {
        return null;
      }
      return state.routerHistory[historyLen - 1].path;
    },
    /**
     * Get the route history stack
     * @param {*} state
     * @returns The array of route history
     */
    getRouterHistory(state) {
      return [...state.routerHistory];
    },
  };

  const actions = {
    setRouterLoggingIn({ commit }, isLoggingIn) {
      commit("setLoggingIn", isLoggingIn);
    },
    async getSystemStatus({ commit }) {
      commit("setSystemStatus", {
        type: "error",
        value: null,
      });

      try {
        commit("setSystemStatus", {
          type: "loading",
          value: true,
        });

        const {
          data: { data: status },
        } = await app.config.globalProperties.$http.get("/v1/api/status");
        commit("setSystemStatus", {
          type: "powerBIStatus",
          value: status.powerBIStatus,
        });
        commit("setSystemStatus", {
          type: "gdwStatus",
          value: status.gdwConnection,
        });
        commit("setSystemStatus", {
          type: "onPremiseConnectionStatus",
          value: status.onPremConnection,
        });
      } catch (error) {
        commit("setSystemStatus", {
          type: "error",
          value: error,
        });
      } finally {
        commit("setSystemStatus", {
          type: "loading",
          value: false,
        });
      }
    },
    /**
     * Show the UI for activating the service worker / reloading the page
     * @param {*} param0
     * @param {boolean} requiresUpgrade true to show UI
     */
    setServiceWorkerUpgrade({ commit }, requiresUpgrade) {
      commit("setServiceWorkerUpgrade", requiresUpgrade);
    },
    /**
     * Add a route to the route history array
     * @param {*} param0
     * @param {Object} route the route object
     */
    addRoutePathToHistory({ commit }, route) {
      commit("addRouteToHistory", route);
    },
  };

  const mutations = {
    addRouteToHistory(state, route) {
      // Add the new route at the beginning of the array
      state.routerHistory.unshift(route);
      // If more than 5 then remove the oldest (last) entry
      if (state.routerHistory.length > 5) {
        state.routerHistory.pop();
      }
    },
    setLoggingIn(state, isLoggingIn) {
      state.isRouterLoggingIn = isLoggingIn;
    },
    setFeatureFlagsUpdated(state, featureFlagsUpdated) {
      state.featureFlagsUpdated = featureFlagsUpdated;
    },
    setSystemStatus(state, payload) {
      state.systemStatus[payload.type] = payload.value;
    },
    setServiceWorkerUpgrade(state, requiresUpgrade) {
      state.requiresServiceWorkerUpgrade = requiresUpgrade;
    },
  };

  const state = {
    systemStatus: {
      loading: false,
      error: null,
      powerBIStatus: {},
      gdwStatus: {},
      onPremiseConnectionStatus: {},
    },
    isRouterLoggingIn: false,
    featureFlagsUpdated: false,
    showSettingsMenu: false,

    // Service Worker Status
    requiresServiceWorkerUpgrade: false,
    // Store router history
    routerHistory: [],
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
