export default class BlogsApi {
  constructor(http) {
    this.http = http;
  }

  /**
   * Get all blogs by user id
   * @param String id The user id / email address
   */
  async getBlogsByUserId(id) {
    return this.http.get("https://api.hsf.com/articles/v2", {
      params: {
        includefields: "id,title,summary,published",
        author: id,
      },
      headers: {
        "subscription-key": import.meta.env.VITE_API_BLOG_SUBSCRIPTION_KEY,
      },
    });
  }
}
