/**
 * Return the error message
 * @param {Object} error
 **/
export function parseError(error) {
  let ErrorText = error;
  if (error.response?.data?.innerError) {
    ErrorText = error.response?.data?.innerError;
  } else if (error.response?.data?.message) {
    ErrorText = error.response?.data?.message;
  } else if (error.response?.data?.error?.message) {
    ErrorText = "Error: " + error.response?.data?.error?.message;
  } else if (error.response?.data?.error?.innerError) {
    ErrorText = error.response?.data?.error?.innerError;
  } else if (error.response?.data?.userMessage) {
    ErrorText = error.response?.data?.userMessage;
  }
  return ErrorText;
}
