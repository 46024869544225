import qs from "qs";
const { stringify } = qs;

export default class ClientAPI {
  /** The selected financial year */
  #selectedFinancialYear = undefined;
  #stagingENV = `${import.meta.env.VITE_STAGING_ENV}`;
  #baseURL = `https://api.app.hsf.com/Hurricane-api-cloud`;

  constructor(http) {
    this.http = http;
  }

  /**
   * Set the financial year to fetch data for
   * @param {String} [id=undefined] The financial year ID, or the current year if not supplied
   * @returns {SectorAPI}
   */
  setFinancialYear(id = undefined) {
    this.#selectedFinancialYear = id;
    return this;
  }

  /**
   * Get the client by it's id
   * @param {String} id The Client Code to search by
   */
  async getClientById(id) {
    if (this.#stagingENV && this.#stagingENV !== "undefined") {
      return this.http.get(`${this.#baseURL}/v2/clients/${id}`, {
        params: {
          financialYear: this.#selectedFinancialYear,
        },
      });
    }
    return this.http.get(`/v2/clients/${id}`, {
      params: {
        financialYear: this.#selectedFinancialYear,
      },
    });
  }

  /**
   * Get the client's last activity by it's id
   * @param {String} id The Client Code to search by
   */
  async getClientLastActivityById(id) {
    return this.http.get(`/v2/clients/${id}/lastactivity`, {
      params: {
        financialYear: this.#selectedFinancialYear,
      },
    });
  }

  /**
   * Get further client statistics by id
   * @param {String} id The Client Code to search by
   */
  async getClientStatsById(id) {
    if (this.#stagingENV && this.#stagingENV !== "undefined") {
      return this.http.get(`${this.#baseURL}/v2/clients/${id}/statistics`, {
        params: {
          financialYear: this.#selectedFinancialYear,
        },
      });
    }
    return this.http.get(`/v2/clients/${id}/statistics`, {
      params: {
        financialYear: this.#selectedFinancialYear,
      },
    });
  }

  /**
   * Return only a list of domains for a client id
   * @param {String} id The Client Code to Search by
   */
  async getClientDomainsById(id) {
    return this.http.get(`/v1/clients/${id}/domains`);
  }

  /**
   * Return a list of contacts for the client from Foundation
   * @param {String} id The Client Code to Search by
   */
  async getClientContactsById(id) {
    return this.http.get(`/v1/clients/${id}/contacts`);
  }

  /**
   * Return a list of matters for the client from GDW
   * @param {String} id The Client Code to Search by
   */
  async getClientMattersById(id) {
    return this.http.get(`/v2/clients/${id}/matters`, {
      params: {
        financialYear: this.#selectedFinancialYear,
      },
    });
  }

  /**
   * Return a list of matters for the client billing entity
   * @param {String} id The Client Code
   * @param {String} entityId The entity id
   */
  async getClientEntityById(id, entityId) {
    return this.http.get(`/v1/clients/${id}/billingEntities/${entityId}`);
  }

  /**
   * Return Client Relationship Partners for Client
   * @param {String} id Client Group ID
   * @returns Promise
   */
  async getClientPartnersById(id) {
    if (!id && typeof id !== "string") {
      throw new Error("Missing <string> Client Group Code");
    }

    return this.http.get(
      `${
        import.meta.env.VITE_HSF_API_BASE_URL
      }/sectorhub/v1/clients/${id}/people/relationship-partners`
    );
  }

  /**
   * Return Client people : relationship partners, timekeepers
   * and contacts
   * @param {String} id Client Group ID
   * @returns Promise
   */
  async getClientPeople(id) {
    if (!id && typeof id !== "string") {
      throw new Error("Missing <string> Client Group Code");
    }

    return this.http.get(
      `${
        import.meta.env.VITE_HSF_API_BASE_URL
      }/sectorhub/v1/clients/${id}/people`,
      {
        params: {
          "include-more-details": true,
        },
      }
    );
  }

  /**
   * Search only active and open client code or client name for clients
   * @param {String} phrase The term to search for
   * @param {Boolean} onlyActive get active or all clients
   */
  async search(phrase, onlyActive = false) {
    return this.http.post(
      "/v1/find",
      { cgn: { active: onlyActive } },
      {
        params: {
          q: phrase.trim(),
          engines: ["CGN"],
          limit: 50,
          offset: 0,
        },
        paramsSerializer: (params) =>
          stringify(params, { arrayFormat: "repeat" }),
      }
    );
  }

  /**
   * Return Priroity client in Sector(s)
   * @param {String} clientCode Group ID
   * @returns Promise
   */
  async getPriorityClientInSector(clientCode) {
    if (!clientCode && typeof clientCode !== "string") {
      throw new Error("Missing <string> Client Group Code");
    }

    return this.http.get(
      `${
        import.meta.env.VITE_HSF_API_BASE_URL
      }/sectorhub/v1/sectors/clients/${clientCode}`
    );
  }

  /**
   * Return client Credentials in Sector(s)
   * @param {String} clientCode Group ID
   * @returns Promise
   */
  async getClientCredentials(clientCode) {
    return this.http.get(
      `${
        import.meta.env.VITE_HSF_API_BASE_URL
      }/sectorhub/v1/clients/${clientCode}/credentials`,
      {
        params: {
          fy: this.#selectedFinancialYear,
        },
      }
    );
  }
}
