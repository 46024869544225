export default [
  {
    path: "/terminology",
    redirect: { name: "terminology" },
  },
  {
    path: "/tools/terminology",
    name: "terminology",
    component: () => import("@/modules/wiki/_views/TerminologyHome.vue"),
    meta: {
      title: "Terminology",
    },
  },
];
