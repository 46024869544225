<script setup>
import { inject, watch, ref } from "vue";
import { useRoute } from "vue-router";

const showMenu = inject("menuDrawer", false);
const route = useRoute();
const hasSidebar = ref(route?.meta?.hasSidebar ?? false);
watch(route, (to) => {
  hasSidebar.value = to?.meta?.hasSidebar;
});
</script>

<template>
  <v-app-bar color="primary-lighten-2" flat>
    <template v-if="hasSidebar === true" #prepend>
      <v-app-bar-nav-icon
        id="mobileMenu"
        :icon="showMenu ? 'mdi-backburger' : 'mdi-menu'"
        @click.stop="showMenu = !showMenu"
      />
    </template>
    <search-box />

    <notifications-icon v-if="$featureFlag.isVisible('enableNotifications')" />

    <user-settings :avatar-size="36" />

    <reload-site min-width="100%" />
  </v-app-bar>
</template>
