export default class PersonApi {
  constructor(http) {
    this.http = http;
    this.apiHostHeader = {
      "x-forwarded-host": window.location.host,
    };
    this.baseUrl = `${import.meta.env.VITE_HSF_API_BASE_URL}/sectorhub`;
  }

  /**
   * Get basic info from MS Graph
   * @param String id The user id / email address
   */
  async getPerson(id) {
    return this.http.get(`https://graph.microsoft.com/beta/users/${id}`);
  }

  /**
   * Get MS graph data enriched with other data about the user
   * @param String id The user id / email address
   */
  async getPersonEnriched(id) {
    return this.http.get(`/v1/persons/${id}`);
  }

  /**
   * Get user experience from Foundation
   * @param {String} id The user id / email address
   * @returns {Promise}
   */
  async getFoundationExperience(id) {
    if (!id && typeof id !== "string") {
      throw new Error("`id` must be an email address for the user");
    }

    return this.http.get(`${this.baseUrl}/v1/persons/${id}/credentials`);
  }

  /**
   * Get a list of sectors the user has worked in over a time period to now
   * @param {String} email An email address to check for
   * @param {Date|null} [startDate=null] A date to start looking for work in sectors, if null defaults to previous 2 years
   * @returns {Promise}
   */
  async getSectorsByEmail(email, startDate = null) {
    if (!email && typeof email !== "string") {
      throw new Error("`email` must be an email address for the user");
    }

    return this.http.get(`${this.baseUrl}/v1/persons/${email}/sectors`, {
      params: {
        start_date: startDate,
      },
    });
  }

  /**
   * Get all the experiences related to the current person.
   * @returns {Promise}
   */
  async getPersonExperience() {
    return this.http.get(`${this.baseUrl}/v1/persons/experiences`, {
      headers: this.apiHostHeader,
    });
  }

  /**
   * Get all the experiences related to the current person from foundation
   * @param {string} email current users email
   * @returns {Promise}
   */
  async getExistingFoundationExperience(email) {
    return this.http.get(`${this.baseUrl}/v1/persons/${email}/credentials`);
  }

  /**
   * Save  the experience related to the current person.
   * @param {Object} experience - A particular experience of the user
   * @param {String} experience.sectorCode - Sector for experience
   * @param {String} experience.subSectorCode - Sub Sector for experience
   * @param {Array} experience.countries - [{id: "32"}] The country list for experience
   * @param {Array} experience.regions - [{code: "Europe"}] The selected region for experience
   * @param {Array} experience.workType - [{groupCode: "APL"}] for Type of work
   * @returns {Promise}
   */
  async savePersonExperience(experience) {
    if (!experience && typeof experience !== "object") {
      throw new Error("`experience` must be an object for the user");
    }
    const data = [
      {
        name: experience.name,
        email: experience.email,
        experiences: [
          {
            sector: {
              sectorCode: experience.sectorCode,
            },
            subSectors: experience.subSectorCode,
            countries: experience.countries,
            regions: experience.regions,
            worktypes: experience.workTypes,
          },
        ],
      },
    ];

    return this.http.post(`${this.baseUrl}/v1/persons/experiences`, data, {
      headers: this.apiHostHeader,
    });
  }

  /**
   * Save  the experience related to the current person.
   * @param {Object[]} userExperience - Save multiple experience entries at once
   * @param {string} userExperience[].name - The name of the user
   * @param {string} userExperience[].email - The email address of the user
   * @param {Object[]} userExperience[].experiences - An array of experience entries
   * @param {String} userExperience[].experiences[].sector - The sector code for the experience
   * @param {string} userExperience[].experiences[].sector.sectorCode - The sector code for the experience
   * @param {Object[]} userExperience[].experiences[].subSectorCode - Array of sub sectors
   * @param {String} userExperience[].experiences[].subSectorCode[].subSectorCode - sub sector id
   * @param {Object[]} userExperience[].experiences[].countries - Array of countries
   * @param {String} userExperience[].experiences[].countries[].id - Id of country
   * @param {Object[]} userExperience[].experiences[].regions - Array of object
   * @param {String} userExperience[].experiences[].regions[].code - Code of Region
   * @param {Object[]} userExperience[].experiences[].workTypes - Array of work types
   * @param {String} userExperience[].experiences[].workTypes[].groupCode - Code of group work type
   * @param {Object[]} userExperience[].experiences[].workTypes[].subworktypes - Array of sub work types
   * @param {String} userExperience[].experiences[].workTypes[].subworktypes[].typeCode - Code of sub work type
   * @returns {Promise}
   */
  async saveMultiplePersonExperience(userExperience) {
    if (!userExperience && !Array.isArray(userExperience)) {
      throw new Error("`experiences` must be an array of objects for the user");
    }
    const data = userExperience.map((experience) => {
      return {
        name: experience.name,
        email: experience.email,
        experiences: [
          {
            sector: {
              sectorCode: experience.sectorCode,
            },
            subSectors: experience.subSectorCode,
            countries: experience.countries,
            regions: experience.regions,
            worktypes: experience.workTypes,
          },
        ],
      };
    });

    return this.http.post(`${this.baseUrl}/v1/persons/experiences`, data, {
      headers: this.apiHostHeader,
    });
  }

  /**
   * Update the experience related to an id.
   * @param {Number} id - An id of exisiting experience
   * @param {Object} experience - A particular experience of the user
   * @param {String} experience.sectorCode - Sector for experience
   * @param {String} experience.subSectorCode - Sub Sector for experience
   * @param {Array} experience.countries - [{id: "32"}] The country list for experience
   * @param {Array} experience.regions - [{code: "Europe"}] The selected region for experience
   * @param {Array} experience.workType - [{groupCode: "APL"}] for Type of work
   * @returns {Promise}
   */
  async updatePersonExperience(id, experience) {
    if (!id && typeof id !== "number") {
      throw new Error("`id` must be a number for the experience");
    }

    if (!experience && typeof experience !== "object") {
      throw new Error("`experience` must be an object for the user");
    }
    const data = [
      {
        email: experience.email,
        experiences: [
          {
            id: id,
            sector: {
              sectorCode: experience.sectorCode,
            },
            subSectors: experience.subSectorCode,
            countries: experience.countries,
            regions: experience.regions,
            worktypes: experience.workTypes,
          },
        ],
      },
    ];

    return this.http.put(`${this.baseUrl}/v1/persons/experiences`, data, {
      headers: this.apiHostHeader,
    });
  }

  /**
   * Remove a list of experiences for the user
   * @param {String} email of the user to whom experience belongs
   * @param {Object[]} ids - An array of ids that need to be removed.
   * @param {number} ids[].id - The id of the record to be removed.
   */
  async removePersonExperiences(email, ids) {
    if (!email && typeof email !== "string") {
      throw new Error("`email` must be an string for the user");
    }

    if (!Array.isArray(ids)) {
      throw new Error(
        "Parameter `ids` must be an array of objects representing the rows to delete."
      );
    }

    const idsToRemove = ids.map((value) => ({ id: value }));
    const data = [
      {
        email: email,
        experiences: idsToRemove,
      },
    ];

    return this.http.delete(
      `${this.baseUrl}/v1/persons/experiences`,
      { data: data },
      { headers: this.apiHostHeader }
    );
  }
}
