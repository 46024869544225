<template>
  <v-card
    :key="$route.name"
    :elevation="$route.meta.darkBg ? 0 : 4"
    min-height="92vh"
    class="ml-sm-2 mt-sm-5 mr-sm-2 transition-frame"
    :class="getExtraClasses"
    :color="getCardColour"
  >
    <v-container fluid class="pa-0">
      <!-- @see https://router.vuejs.org/guide/advanced/transitions.html#per-route-transition -->
      <router-view v-slot="{ Component }">
        <transition :name="'slide-right'" :mode="'out-in'">
          <component :is="Component" :key="$route.path" />
        </transition>
      </router-view>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "AppBody",
  computed: {
    getCardColour() {
      return this.$route.meta?.darkBg ? "primary-lighten-2" : "background";
    },
    getExtraClasses() {
      return this.$route.meta?.darkBg ? "" : "rounded-sm-tl-xl darker-shadow";
    },
  },
};
</script>

<style scoped>
.darker-shadow {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.6) !important;
}

.transition-frame {
  transition: all 0.3 linear;
}
</style>
