const SECTOR_OVERVIEW = "view-sector";
const SUB_SECTOR_OVERVIEW = "view-sub-sector";
const IMPORT_SECTOR_VIEW = () => {
  return import("@/modules/sectors/_views/SectorView.vue");
};
const IMPORT_OPPORTUNITY_LIST = () => {
  return import("@/modules/sectors/_views/SectorOpportunities.vue");
};
const IMPORT_OPPORTUNITY_VIEW = () => {
  return import("@/modules/sectors/_views/SectorOpportunity.vue");
};
const IMPORT_EXPERIENCE_LIST = () => {
  return import("@/modules/sectors/_views/SectorExperiences.vue");
};
const IMPORT_OPPORTUNITY_ADD = () => {
  return import("@/modules/sectors/_views/AddOpportunity.vue");
};

export default [
  {
    path: "/sectors",
    name: "sectors",
    redirect: { name: "sector-dashboard" },
  },
  {
    path: "/sectors/dashboard",
    name: "sector-dashboard",
    component: () => import("@/modules/sectors/_views/SectorDashboard.vue"),
    meta: {
      title: "Sectors",
      hasSidebar: true,
    },
  },
  {
    path: "/sectors/:id",
    props: true,
    redirect: { name: SECTOR_OVERVIEW },
    component: () => import("@/modules/sectors/_views/SectorShell.vue"),

    children: [
      {
        path: "overview",
        props: true,
        name: SECTOR_OVERVIEW,
        component: IMPORT_SECTOR_VIEW,
        meta: {
          title: "Sectors // {id}",
          hasSidebar: true,
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: "sector-dashboard",
          },
        },
      },
      {
        path: "clients",
        name: "view-sector-clients",
        component: () => import("@/modules/sectors/_views/SectorClients.vue"),
        meta: {
          title: "Sectors // {id} // Clients",
          hasSidebar: true,
          pageTitle: "Clients",
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "matters",
        name: "view-sector-matters",
        component: () => import("@/modules/sectors/_views/SectorMatters.vue"),
        meta: {
          title: "Sectors // {id} // Matters",
          hasSidebar: true,
          pageTitle: "Matters",
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "people",
        name: "view-sector-people",
        component: () => import("@/modules/sectors/_views/SectorPeople.vue"),
        meta: {
          title: "Sectors // {id} // People",
          hasSidebar: true,
          pageTitle: "People",
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "sub-sector/:subsectorid",
        name: "view-sub-sector",
        props: true,
        component: IMPORT_SECTOR_VIEW,
        meta: {
          title: "Sectors // Sub Sector // {subsectorid}",
          hasSidebar: true,
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "revenue",
        name: "view-sector-revenue",
        component: () => import("@/modules/sectors/_views/SectorRevenue.vue"),
        meta: {
          title: "Sectors // {id} // Revenue",
          hasSidebar: true,
          pageTitle: "Sector Revenue",
          showSlicer: true,
          showFY: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "experiences/list",
        name: "view-sector-experiences",
        component: IMPORT_EXPERIENCE_LIST,
        meta: {
          title: "Sectors // {id} // Experiences",
          pageTitle: "Sector Experience",
          showSlicer: false,
          showFY: true,
          hasSidebar: true,
          isFiltered: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "sub-sector/:subsectorid/experiences/list",
        name: "view-sub-sector-experiences",
        component: IMPORT_EXPERIENCE_LIST,
        meta: {
          title: "Sub Sector // {subsectorid} // Experiences",
          hasSidebar: true,
          pageTitle: "Experiences",
          showSlicer: false,
          showFY: true,
          backButtonRoute: {
            name: SUB_SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "opportunities/list",
        name: "view-sector-opportunities",
        component: IMPORT_OPPORTUNITY_LIST,
        meta: {
          title: "Sectors // {id} // Opportunities",
          pageTitle: "Opportunities",
          showSlicer: false,
          showFY: true,
          hasSidebar: true,
          addOpportunity: true,
          backButtonRoute: {
            name: SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "opportunities/add",
        name: "add-sector-opportunity",
        component: IMPORT_OPPORTUNITY_ADD,
        meta: {
          title: "Sectors // {id} // Opportunities // Add",
          pageTitle: "Create Opportunity",
          showSlicer: false,
          showFY: false,
          hasSidebar: true,
          backButtonRoute: {
            name: "view-sector-opportunities",
          },
        },
      },
      {
        path: "opportunities/:opportunityId",
        name: "view-sector-opportunity",
        props: true,
        component: IMPORT_OPPORTUNITY_VIEW,
        meta: {
          title: "Sectors // {id} // Opportunity // View",
          hasSidebar: true,
          pageTitle: "Opportunities",
          showSlicer: false,
          showFY: false,
          backButtonRoute: {
            name: "view-sector-opportunities",
          },
        },
      },
      {
        path: "sub-sector/:subsectorid/opportunities/list",
        name: "view-sub-sector-opportunities",
        component: IMPORT_OPPORTUNITY_LIST,
        meta: {
          title: "Sub Sector // {subsectorid} // Opportunities",
          hasSidebar: true,
          pageTitle: "Opportunities",
          showSlicer: false,
          showFY: true,
          backButtonRoute: {
            name: SUB_SECTOR_OVERVIEW,
          },
        },
      },
      {
        path: "sub-sector/:subsectorid/opportunities/add",
        name: "add-sub-sector-opportunity",
        component: IMPORT_OPPORTUNITY_ADD,
        meta: {
          title: "Sub Sector // {subsectorid} // Opportunities // Add",
          pageTitle: "Create Opportunity",
          showSlicer: false,
          showFY: false,
          hasSidebar: true,
          backButtonRoute: {
            name: "view-sub-sector-opportunities",
          },
        },
      },
      {
        path: "sub-sector/:subsectorid/opportunities/:opportunityId",
        name: "view-sub-sector-opportunity",
        props: true,
        component: IMPORT_OPPORTUNITY_VIEW,
        meta: {
          title: "Sub Sectors // {subsectorid} // Opportunity // View",
          hasSidebar: true,
          pageTitle: "Opportunities",
          showSlicer: false,
          showFY: false,
          backButtonRoute: {
            name: "view-sub-sector-opportunities",
          },
        },
      },
    ],
  },
  // Additional route for magnolia content
  {
    path: "/sectors/dxp",
    name: "SectorMagnoliaContent",
    component: () =>
      import("@/modules/sectors/_views/SectorMagnoliaContent.vue"),
    meta: {
      title: "Magnolia Content",
      pageTitle: "Magnolia Demo Content",
      hasSidebar: true,
      backButtonRoute: {
        name: SECTOR_OVERVIEW,
      },
    },
  },
];
