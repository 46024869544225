import { defineAsyncComponent } from "vue";
import { engineStrategies } from "../strategies/EngineStrategies.js";

const SearchEnginesMixin = {
  components: {
    mbn: defineAsyncComponent(
      () => import("@/modules/search/_components/MatterByName.vue")
    ),
    mbc: defineAsyncComponent(
      () => import("@/modules/search/_components/MatterByCode.vue")
    ),
    obn: defineAsyncComponent(
      () => import("@/modules/search/_components/OrgByName.vue")
    ),
    abc: defineAsyncComponent(
      () => import("@/modules/search/_components/ArticlesByContents.vue")
    ),
    pbn: defineAsyncComponent(
      () => import("@/modules/search/_components/PersonByName.vue")
    ),
    cbn: defineAsyncComponent(
      () => import("@/modules/search/_components/ContactByName.vue")
    ),
    gbn: defineAsyncComponent(
      () => import("@/modules/search/_components/MatterGroupByName.vue")
    ),
    ben: defineAsyncComponent(
      () => import("@/modules/search/_components/BillingEntityByName.vue")
    ),
    cgn: defineAsyncComponent(
      () => import("@/modules/search/_components/ClientGroupByName.vue")
    ),
    prn: defineAsyncComponent(
      () => import("@/modules/search/_components/ProspectByName.vue")
    ),
    pbe: defineAsyncComponent(
      () => import("@/modules/search/_components/PersonByName.vue")
    ),
    abn: defineAsyncComponent(
      () => import("@/modules/search/_components/ArbitrationByName.vue")
    ),
    ltg: defineAsyncComponent(
      () => import("@/modules/search/_components/LitigationByName.vue")
    ),
    sbn: defineAsyncComponent(
      () => import("@/modules/search/_components/SectorAndSubSectorByName.vue")
    ),
  },
  methods: {
    /**
     * Determines if the given item has results.
     *
     * @param {Object} item - The item to evaluate.
     * @param {string} item.engineId - The ID of the engine associated with the item.
     * @param {Array} item.results - The results array of the item.
     * @return {boolean} - True if the item has results. For "PBN" engineId items, true if there is at least one result with a non-null `mail`.
     */
    getItemLength({ engineId, results }) {
      const strategy =
        engineStrategies[engineId.toLowerCase()] || engineStrategies.default;
      return strategy.getItemLength(results);
    },
    /**
     * Retrieves search engine results
     *
     * @param {Object} item - The item from which to retrieve results.
     * @param {string} item.engineId - The ID of the engine associated with the item.
     * @param {Array} item.results - The results array of the item.
     * @return {Array} - An array of results. If the engineId is "PBN", the array includes only results with a non-null
     *                   `person.data.mail`. For other engineIds, the array includes all results.
     */
    getItemResults({ engineId, results }) {
      const strategy =
        engineStrategies[engineId.toLowerCase()] || engineStrategies.default;
      return strategy.getItemResults(results);
    },
  },
};

export default SearchEnginesMixin;
