import { LogLevel } from "@azure/msal-browser";

const logger = {
  loggerCallback: (level, message, containsPii) => {
    if (containsPii) {
      return;
    }

    // In Production, only output error messages.
    if (import.meta.env.PROD) {
      if (level === LogLevel.Error) {
         
        console.error(message);
      }
      return;
    }

    switch (level) {
      case LogLevel.Error:
         
        console.error(message);
        return;
      case LogLevel.Info:
        // eslint-disable-next-line
        console.info(message);
        return;
      case LogLevel.Verbose:
        // eslint-disable-next-line
        console.debug(message);
        return;
      case LogLevel.Warning:
         
        console.warn(message);
        return;
    }
  },
  piiLoggingEnabled: import.meta.env.VITE_AUTH_AZ_LOGGING_SHOW_PERSONAL_INFO,
};

const AuthenticationOptions = {
  auth: {
    clientId: import.meta.env.VITE_AUTH_AZ_CLIENT_ID,
    redirectUri: import.meta.env.VITE_AUTH_AZ_LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: import.meta.env.VITE_AUTH_AZ_LOGOUT_REDIRECT_URL,
    authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
    knownAuthorities: [],
    navigateToLoginRequestUrl: false,
    cloudDiscoveryMetadata: "",
  },
  cache: {
    cacheLocation: import.meta.env.VITE_AUTH_AZ_CACHE_LOCATION,
    temporaryCacheLocation: import.meta.env.VITE_AUTH_AZ_CACHE_LOCATION,
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: logger,
  },
};

const AuthenticationParametersHSFAPI = {
  scopes: [import.meta.env.VITE_API_SCOPE],
  authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
};

const AuthenticationParametersRelativityAPI = {
  scopes: ["https://api.app.hsf.com/relativity/Relativity.Read"],
  authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
};

const AuthenticationParametersHSFBlogsAPI = {
  scopes: [import.meta.env.VITE_API_SCOPE],
  authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
  subscriptionKey: import.meta.env.VITE_API_BLOG_SUBSCRIPTION_KEY,
};

const AuthenticationParametersHSFLegacyAPI = {
  scopes: ["https://api.herbertsmithfreehills.com/user_impersonation"],
  authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
};

const AuthenticationParametersMSGraph = {
  scopes: [
    "User.Read", // Get current user's profile
    "User.Read.All", // Get all user's profiles
    "People.Read", // Read user's connected contacts
    "Directory.Read.All", // Read all users
    "Sites.Read.All", // Read Sharepoint lists
    "Presence.Read.All", // Get all user presence
    "MailboxSettings.Read", // Get user timezone
    "Group.ReadWrite.All", // Create matter services
    "Directory.ReadWrite.All", // Create groups/geolocations in conjunction with above
  ],
  authority: import.meta.env.VITE_AUTH_AZ_AUTHORITY,
};

/**
 * Method to decide which AuthenticationParameters object to use based on URL
 * @param {*} url A URL to be called
 */
const authenticationParametersFactory = (url) => {
  let config;
  switch (true) {
    case url.startsWith("https://graph.microsoft.com"):
      config = AuthenticationParametersMSGraph;
      break;
    case url.startsWith("https://api.herbertsmithfreehills.com"):
      config = AuthenticationParametersHSFLegacyAPI;
      break;
    case url.startsWith(import.meta.env.VITE_HSF_RELATIVITY_API_BASE_URL):
      config = AuthenticationParametersRelativityAPI;
      break;
    case url.startsWith("/"):
    case url.startsWith(import.meta.env.VITE_HSF_API_BASE_URL):
    case url.startsWith(import.meta.env.VITE_PUSH_BASE_URL):
    case url.startsWith(import.meta.env.VITE_BASE_URL):
      config = AuthenticationParametersHSFAPI;
      break;
    case url.startsWith("https://api.hsf.com/articles"):
      config = AuthenticationParametersHSFBlogsAPI;
      break;
    case url.startsWith("https://api.xmltime.com"):
      config = {};
      break;
    default:
      throw new Error("Getting token for this API Not Supported.");
  }

  return config;
};

const ApprovedIdpList = [import.meta.env.VITE_HBT_LAW_IDP];

export {
  AuthenticationOptions,
  AuthenticationParametersMSGraph,
  AuthenticationParametersHSFAPI,
  authenticationParametersFactory,
  ApprovedIdpList,
};
