<template>
  <v-footer
    position="absolute"
    app
    color="primary"
    class="d-flex justify-space-between text-white"
  >
    <div>Confidential</div>
    <div>
      Herbert Smith Freehills LLP &copy;
      <span class="currentYear">{{ currentYear }}</span>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
