import { reactive } from "vue";

export const state = reactive({
  online: navigator.onLine,
});

export function isOnline() {
  return state.online;
}

export function addEventListeners(updateOnlineStatus) {
  window.addEventListener("online", () => {
    state.online = true;
    updateOnlineStatus();
  });
  window.addEventListener("offline", () => {
    state.online = false;
    updateOnlineStatus();
  });
}

export function removeEventListeners(updateOnlineStatus) {
  window.removeEventListener("online", updateOnlineStatus);
  window.removeEventListener("offline", updateOnlineStatus);
}
