<template>
  <v-container fluid class="not-logged-in">
    <v-row class="flex-column flex-nowrap">
      <v-col cols="12">
        <img
          src="@/assets/hsf-logo-white.png"
          alt="HSF Logo"
          class="hsf-logo"
        >
      </v-col>
      <v-col cols="12">
        <app-name />
      </v-col>
      <v-col v-if="loginError" cols="12" class="ma-5">
        <v-alert :value="loginError !== null" color="error">
          {{ errorNice }}
        </v-alert>
      </v-col>
      <v-col v-if="!isIE" cols="12">
        <v-btn
          id="loginButton"
          ref="loginButton"
          color="accent"
          class="px-6"
          size="large"
          append-icon="mdi-login-variant"
          :loading="isLoading || isRouterLoggingIn"
          @click="login"
        >
          Login
        </v-btn>
      </v-col>
      <v-col v-else cols="12">
        <v-alert type="error" width="60%" class="mx-auto accent-darken-1">
          <app-name class="d-inline" /> requires a newer browser to be used.
          Please try Chrome, Safari, Firefox or Edge.
        </v-alert>
      </v-col>
    </v-row>

    <app-footer />
  </v-container>
</template>

<script>
import BrowserCheck from "@/services/browserCheck.js";
import { mapState } from "vuex";
import { ref } from "vue";

export default {
  name: "LoginScreen",
  props: {
    loginError: {
      type: String,
      default: undefined,
    },
    loginCode: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    /**
     * setup the login button from ref
     */
    const loginButton = ref(null);
    return {
      loginButton,
    };
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    /**
     * Check whether the browser is IE or not
     */
    isIE() {
      return BrowserCheck.isBrowserIE();
    },
    /**
     * Get a nicely formatted error message from the MSAL response
     */
    errorNice() {
      let msg = this.loginError;
      if (this.loginCode === "access_denied") {
        msg = "Access Denied.";
      }

      return msg;
    },
    ...mapState("core", {
      isRouterLoggingIn: (state) => state.isRouterLoggingIn,
    }),
  },
  watch: {
    /**
     * Set focus on the button when login button is loaded
     */
    loginButton(to) {
      if (to.loading === false) {
        loginButton.focus();
      }
    },
  },
  methods: {
    /**
     * Run login
     */
    login: function () {
      this.isLoading = true;
      this.$auth.login({ target: this.$route.path || "/" });
    },
    /**
     * Run logout
     */
    logout: function () {
      this.$auth.logout();
    },
  },
};
</script>

<style scoped>
.not-logged-in {
  height: 100vh;
  width: 100vw;
  background-color: rgb(var(--v-theme-primary));
  color: #ffffff;
  text-align: center;
  padding-top: 35vh;
  z-index: 10000;
}

.hsf-logo {
  width: 250px;
}
</style>
