import { watch, ref, onBeforeMount } from "vue";
import { useFavicon } from "@vueuse/core";
import { SETTING_THEME } from "@/config/constants.js";
import { useSettings } from "@/composables/UserSettings.js";
const DEFAULT_THEME = "customLightTheme";

/**
 * Check for "auto" theme based on browser preferences
 * @param {string} theme The theme name from the v-btn
 */
const getTheme = (theme) => {
  if (theme === "auto") {
    const browserDarkMode = window?.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    return browserDarkMode ? "customDarkTheme" : "customLightTheme";
  }

  return theme;
};

const useHurricaneTheme = () => {
  /** Used to return what theme is actually picked (handle auto mode; as in if saved value is "auto" we still have to choose between light or dark) */
  const savedTheme = useSettings(SETTING_THEME, DEFAULT_THEME);

  const selectedTheme = ref(DEFAULT_THEME);

  // Create reactive favicon
  const icon = useFavicon();

  // Set the initial state based on indexedDB
  onBeforeMount(() => {
    selectedTheme.value = getTheme(savedTheme.value);
  });

  // Watch for changes to the mode and update the name + favicon
  watch(
    savedTheme,
    (to) => {
      selectedTheme.value = getTheme(to);

      // Update the Favicon depending on the theme
      icon.value =
        selectedTheme.value === "customDarkTheme"
          ? "/img/icons/favicon-white@32x32.png"
          : "/img/icons/favicon-32x32.png";
    },
    { immediate: true }
  );

  return { selectedTheme, savedTheme };
};

export { useHurricaneTheme };
