export default [
  {
    path: "/blogs",
    name: "blogs",
    redirect: { name: "view-blog" },
  },
  {
    path: "/blogs/view/:id",
    name: "view-blog",
    props: true,
    component: () => import("@/modules/blogs/_views/View.vue"),
    meta: {
      title: "Blogs // View",
    },
  },
];
